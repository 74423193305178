const headers = [
  { label: "SKU", key: "SKU" },
  { label: "Name", key: "Name" },
  { label: "Current Stock", key: "SOH" },
  { label: "Available Stock", key: "CurrentStock" },
  { label: "Unit", key: "Unit" },
  { label: "Usage In Period", key: "UsageInPeriod" },
  { label: "Product Manager", key: "productManager" },
  { label: "Low Stock Level", key: "reorderlevel" },
];
export default headers;
