import React from 'react'

const index = () => {
    return (
        <div className="pagenotfound">
      <div>
       
        <h1>WELCOME</h1>
       
      </div>
    </div>
    )
}

export default index
