export const searchProductByField = (products, searchField, searchValue) => {
  let product = [];
  product = products.filter((foo) => {
    if (searchField === "SKU") {
      return foo.id.toLowerCase().includes(searchValue.toLowerCase());
    }
    if (searchField === "Description") {
      return foo.description.toLowerCase().includes(searchValue.toLowerCase());
    } else {
      return foo;
    }
  });
  return product;
};
