import React from "react";
import headers from "../../csvheaders/stockHeader";
import { CSVLink } from "react-csv";

const StockReportCsv = ({ stock, label }) => {
  const csvReport = {
    data: stock || [],
    headers: headers,
    filename: "stock.csv",
  };

  return (
    <CSVLink {...csvReport} 
    // style={{ color: "#fff" }}
    className="btn button btn-block"
                  style={{ marginTop: "33px" }}
    >
      {label}
    </CSVLink>
  );
};

export default StockReportCsv;
