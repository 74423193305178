import React from "react";
import classnames from "classnames";

const messages = {
  label: {
    manage: "Default Delivery Details",
    name: "Name",
    organization: "Organization",
    town: "Town",
    state: "State",
    postCode: "Post Code",
    address1: "Address 1",
    address2: "Address 2",
    phone: "Phone",
    email: "Email",
  },
};
const UserDeliveryDetials = ({
  changeInput,
  dname,
  dorganization,
  dtown,
  dstate,
  dpostcode,
  daddress1,
  daddress2,
  dphone,
  demail,
  error,
}) => {
  return (
    <div className="card mb-2 border-dark">
      <div className="card-header text-center clientCardHeader">
        <h5>
          <a
            href="#collapse2"
            data-parent="#accordion"
            data-toggle="collapse"
            className="text-white"
          >
            {messages.label.manage}
          </a>
        </h5>
      </div>

      <div className="collapse" id="collapse2">
        <div className="card-body">
          <div className="row">
            <div className="col-md-4">
              <div className="form-group row ">
                <label
                  htmlFor="name"
                  className="col-lg-4 col-form-label label label"
                >
                  {messages.label.name}
                </label>

                <div className="col-lg-8 px-0 px-md-3 px-sm-3">
                  <input
                    type="text"
                    className={classnames("form-control", {
                      "is-invalid": error?.dname,
                    })}
                    value={dname}
                    onChange={(e) => changeInput("dname", e.target.value)}
                  />
                  <small>{error?.dname}</small>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group row ">
                <label
                  htmlFor="name"
                  className="label col-lg-4 col-form-label label"
                >
                  {messages.label.organization}
                </label>
                <div className="col-lg-8 px-0 px-md-3 px-sm-3">
                  <input
                    type="text"
                    className={classnames("form-control wtow", {
                      "is-invalid": error?.dorganization,
                    })}
                    value={dorganization}
                    onChange={(e) =>
                      changeInput("dorganization", e.target.value)
                    }
                  />
                  <small>{error?.dorganization}</small>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <div className="form-group row ">
                <label htmlFor="name" className="col-lg-4 col-form-label label">
                  {messages.label.address1}
                </label>
                <div className="col-lg-8 px-0 px-md-3 px-sm-3">
                  <input
                    type="text"
                    className={classnames("form-control wone", {
                      "is-invalid": error?.daddress1,
                    })}
                    value={daddress1}
                    onChange={(e) => changeInput("daddress1", e.target.value)}
                  />
                  <small>{error?.daddress1}</small>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <div className="form-group row ">
                <label htmlFor="name" className="col-lg-4 col-form-label label">
                  {messages.label.address2}
                </label>
                <div className="col-lg-8 px-0 px-md-3 px-sm-3">
                  <input
                    type="text"
                    value={daddress2}
                    className={classnames("form-control wone", {
                      "is-invalid": error?.daddress2,
                    })}
                    onChange={(e) => changeInput("daddress2", e.target.value)}
                  />
                  <small>{error?.daddress2}</small>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="form-group row ">
                <label
                  htmlFor="name"
                  className="col-lg-4 col-form-label label "
                >
                  {messages.label.town}
                </label>
                <div className="col-lg-8 px-0 px-md-3 px-sm-3">
                  <input
                    type="text"
                    className={classnames("form-control", {
                      "is-invalid": error?.dtown,
                    })}
                    value={dtown}
                    onChange={(e) => changeInput("dtown", e.target.value)}
                  />
                  <small>{error?.dtown}</small>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group row ">
                <label htmlFor="name" className="col-lg-4 col-form-label label">
                  {messages.label.state}
                </label>
                <div className="col-lg-8 px-0 px-md-3 px-sm-3">
                  <input
                    type="text"
                    value={dstate}
                    className={classnames("form-control", {
                      "is-invalid": error?.dstate,
                    })}
                    onChange={(e) => changeInput("dstate", e.target.value)}
                  />
                  <small>{error?.dstate}</small>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group row ">
                <label htmlFor="name" className="col-lg-4 col-form-label label">
                  {messages.label.postCode}
                </label>
                <div className="col-lg-8 px-0 px-md-3 px-sm-3">
                  <input
                    type="number"
                    value={dpostcode}
                    onKeyDown={(e) => {
                      const characterCode = e.key;
                      if (characterCode === "Backspace") return;

                      const characterNumber = Number(characterCode);
                      if (characterNumber >= 0 && characterNumber <= 9) {
                        if (
                          e.currentTarget.value &&
                          e.currentTarget.value.length
                        ) {
                          return;
                        }
                      } else {
                        e.preventDefault();
                      }
                    }}
                    className={classnames("form-control ", {
                      "is-invalid": error?.dpostcode,
                    })}
                    onChange={(e) => changeInput("dpostcode", e.target.value)}
                  />
                  <small>{error?.dpostcode}</small>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <div className="form-group row ">
                <label htmlFor="name" className="col-lg-4 col-form-label label">
                  {messages.label.phone}
                </label>
                <div className="col-lg-8 px-0 px-md-3 px-sm-3">
                  <input
                    type="number"
                    value={dphone}
                    onKeyDown={(e) => {
                      const characterCode = e.key;
                      if (characterCode === "Backspace") return;

                      const characterNumber = Number(characterCode);
                      if (characterNumber >= 0 && characterNumber <= 9) {
                        if (
                          e.currentTarget.value &&
                          e.currentTarget.value.length
                        ) {
                          return;
                        }
                      } else {
                        e.preventDefault();
                      }
                    }}
                    className={classnames("form-control", {
                      "is-invalid": error?.dphone,
                    })}
                    onChange={(e) => changeInput("dphone", e.target.value)}
                  />
                  <small>{error?.dphone}</small>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group row ">
                <label htmlFor="name" className="col-lg-4 col-form-label label">
                  {messages.label.email}
                </label>
                <div className="col-lg-8 px-0 px-md-3 px-sm-3">
                  <input
                    type="email"
                    value={demail}
                    className={classnames("form-control wtow", {
                      "is-invalid": error?.demail,
                    })}
                    onChange={(e) => changeInput("demail", e.target.value)}
                  />
                  <small>{error?.demail}</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDeliveryDetials;
