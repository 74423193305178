import React from "react";

const messages = {
  label: {
    sku: "SKU",
    image: "Image",
    product: "Product",
    quantity: "Quantity",
    unitPrice: "Unit Price",
    total: "Total",
    delivery: "Delivery",
    subTotal: "Total",
    gst: "GST",
    unit: "Units",
  },
};

const CartTable = ({ onClick, client, cardItem, delivery, grandtotal }) => {

  
   const gstIfDeliveryEnabled = (totalPrice,deliveryFee)=>{
    return (((parseFloat(totalPrice) + (deliveryFee)) * 10 ) / 100).toFixed(2);
   }
   const gstIfDeliveryNotEnabled = (totalPrice)=>{
    return ((parseFloat(totalPrice)  * 10 ) / 100).toFixed(2);
   }

   const subTotalIfDeliveryIsEnabled = (grandtotal,delivery)=>{
    return (parseFloat(gstIfDeliveryEnabled(grandtotal,delivery)) + (parseFloat(grandtotal) + parseFloat(delivery))).toFixed(2)
    
   }
   const subTotalIfDeliveryIsNotEnabled = (grandtotal)=>{
    return (parseFloat(gstIfDeliveryNotEnabled(grandtotal)) + (parseFloat(grandtotal))).toFixed(2)
  }
   
  return (
    <div style={{ overflowX: "auto" }}>
      <table className="table">
        <thead className="thead-dark table-md">
          <tr>
            <th>{messages.label.sku}</th>
            <th>{messages.label.image}</th>
            <th>{messages.label.product}</th>
            <th>{messages.label.quantity}</th>
            <th>{messages.label.unit}</th>
            {!client?.nopricing && (
              <>
                <th>{messages.label.unitPrice}</th>
                <th>{messages.label.total}</th>
              </>
            )}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {cardItem.length &&
            cardItem.map((item) => (
              <tr key={item.id}>
                <td>{item.code}</td>
                <td>
                  <img
                    src={item.imageURL.replace(/ /g, "%20")}
                    alt="product-image"
                    className="table-image border"
                  />
                </td>
                <td>{item.name}</td>
                <td>{item.quantity}</td>
                <td>{item.unit}</td>
                {!client?.nopricing && (
                  <>
                    <td>${item.unitPrice.toFixed(2)}</td>
                    <td>${parseFloat(item.totalPrice).toFixed(2)}</td>
                  </>
                )}

                <td>
                  <button
                    className="btn btn-small"
                    onClick={() => onClick(item.id)}
                  >
                    <i className="fa fa-trash" aria-hidden="true"></i>
                  </button>
                </td>
              </tr>
            ))}
          {client && client?.isDeliveryEnabled && cardItem.length !== 0 && (
            <tr>
              <td></td>
              <td></td>
              <td></td>
              {!client?.nopricing && <td></td>}
              <td></td>
              <th>{messages.label.delivery}</th>
              <td>${delivery.toFixed(2)}</td>
            </tr>
          )}
          {client && !client?.nopricing && cardItem.length !== 0 && (
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <th>{messages.label.gst}</th>
              {client?.isDeliveryEnabled ? (
                <td>
                  $
                  {gstIfDeliveryEnabled(grandtotal,delivery)}
                </td>
              ) : (
                <td> ${gstIfDeliveryNotEnabled(grandtotal)}</td>
              )}
            </tr>
          )}
          {client && !client?.nopricing && cardItem.length !== 0 && (
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <th>{messages.label.subTotal}</th>
              {client?.isDeliveryEnabled ? (
                <td>
                  $
                  {/* {(
                    (parseFloat(((parseFloat(grandtotal) + parseFloat(delivery)) * 10) / 100)).toFixed(2)
                   + parseFloat(parseFloat(grandtotal) + parseFloat(delivery))).toFixed(2)} */}
                   {subTotalIfDeliveryIsEnabled(grandtotal,delivery)}
                </td>
              ) : (
                <td>
                  $
                  {/* {(parseFloat(grandtotal) +
                    parseFloat((parseFloat(grandtotal) * 10) / 100)).toFixed(2)} */}
                    {subTotalIfDeliveryIsNotEnabled(grandtotal)}
                </td>
              )}
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default CartTable;
