import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import EssityState from "./context/EssityState";
import history from "./history";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
ReactDOM.render(
  <React.StrictMode>
    <EssityState>
      <Router history={history}>
        <App> </App>
      </Router>
    </EssityState>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.unregister();
