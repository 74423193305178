import React, { useEffect } from "react";

const PageNotFound = () => {
  useEffect(() => {
    // localStorage.removeItem("client");
    // localStorage.removeItem("clientname");
  }, []);
  return (
    <div className="pagenotfound">
      <div>
        <h3>OOPS! CLIENT NOT FOUND</h3>
        <h1>404</h1>
        <p>WE ARE SORRY.BUT THE CLIENT YOU REQUESTED WAS NOT FOUND</p>
      </div>
    </div>
  );
};

export default PageNotFound;
