import React, { useEffect, useState } from "react";
import classnames from "classnames";

const messages = {
  label: {
    sku: "SKU",
    name: "Name",
    image: "Image",
    quantity: "Quantity",
    unit: "Unit",
    availableStock: "Available Stock",

    comment: "Comment",
    each: "Each",
    status: "Status",
  },
};

const OrderReviewTable = ({
  loading,
  setComm,
  setStatus,
  order,
  changeAllLineItemStatus,
  updateAndSubmit,
  changeLineItemStatus
}) => {
  const [statusOption, setStatusOption] = useState([
    "changed",
    "requested",
    "approved",
    "cancelled",
  ]);

  const [isOrderUpdate, setIsOrderUpdate] = useState(false);

  useEffect(() => { }, [order]);

  return (
    <div style={{ overflowX: "auto", width: "100%" }}>
      <table className="table mt-5">
        <thead>
          <tr>
            <th scope="col">{messages.label.sku}</th>
            <th scope="col">{messages.label.name}</th>
            <th scope="col">{messages.label.image}</th>
            <th scope="col">{messages.label.quantity}</th>
            <th scope="col">{messages.label.unit}</th>
            <th scope="col">{messages.label.availableStock}</th>
            <th scope="col">{messages.label.comment}</th>
            <th scope="col">{messages.label.status}</th>
          </tr>
        </thead>
        <tbody>
          {order?.linesItems?.map((item) => (
            <tr key={item._id}>
              <td
                data-toggle="tooltip"
                data-placement="bottom"
                title={item.code}
              >
                {/* {item.code.slice(20, 24)} */}
                {item.code}
              </td>
              <td>{item.name}</td>
              <td>
                <img
                  src={item.imageURL.replace(/ /g, "%20")}
                  alt="product-image"
                  className="table-image border"
                />
              </td>
              <td>
                <input
                  type="number"
                  className="btn-small form-control in-w product-quan addQuantity"
                  disabled={order?.status === "Submitted" ? true : false}
                  defaultValue={item.quantity}
                  min={!item.minimumOrder ? 1 : item.minimumOrder}
                  // step={item.minimumOrder}
                  onKeyDown={(event) => {
                    // event.preventDefault();
                    if (event.keyCode == 109 || event.keyCode == 189 || event.keyCode == 107 || event.keyCode == 187 || event.keyCode == 110 || event.keyCode == 190) {
                      event.preventDefault();
                    }
                  }}
                  onPaste={(event) => {
                    event.preventDefault();
                  }}
                  onChange={(event) => {
                    if (event.target.valueAsNumber <= 999999) {
                      (item.quantity = event.target.valueAsNumber);
                      item.totalPrice = parseFloat(item.quantity) * parseFloat(item.unitPrice)

                    }
                  }
                  }
                />
              </td>
              <td>
                <select
                  className="sel-w form-control selectList"
                  disabled={order?.status === "Submitted" ? true : false}
                >
                  <option>{messages.label.each}</option>
                </select>
              </td>
              <td>{item.currentStock}</td>
              <td>
                <input
                  type="text"
                  className="form-control comm-w addComment"
                  value={item.comment}
                  disabled={order?.status === "Submitted" ? true : false}
                  onChange={(event) =>
                    setComm((item.comment = event.target.value))
                  }
                />
              </td>
              <td>
                <select
                  // defaultValue={item.status}
                  className="form-control sel-w selectList"
                  disabled={order?.status === "Submitted" ? true : false}
                  onChange={(e) => {
                    // setStatus((item.status = e.target.value));
                    changeLineItemStatus(order?._id,item?.id,e.target.value)

                    
                  }}
                  value={item.status}
                >
                  {statusOption.map((options) => (
                    <option value={options} key={options} disabled={options === "changed" ? true : false}>
                      {options}
                    </option>
                  ))}
                </select>
              </td>
            </tr>
          ))}
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>
              <button
                className={classnames("btn button w-100", {
                  disabledBtn: order?.status === "Submitted" ? true : false,
                })}
                onClick={() => {
                  changeAllLineItemStatus(order._id, "approved");
                }}
                disabled={order?.status === "Submitted" ? true : false}
              >
                Approve All
              </button>
            </td>
          </tr>

          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>
              <button
                className={classnames("btn button w-100", {
                  disabledBtn:
                    order?.status === "Submitted" || loading ? true : false,
                })}
                onClick={async (e) => {
                  await updateAndSubmit(order, order?._id);
                  if (order?.status != "Pending") {
                    setIsOrderUpdate(true);
                  }
                }}
                disabled={
                  order?.status === "Submitted" || loading ? true : false || isOrderUpdate
                }
              >
                Update And Submit
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default OrderReviewTable;
