import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import _ from 'lodash'

import Navbar from "../layouts/Navbar";
import EssityContext from "../../context/EssityContext";
import StockReportTable from "./StockReportTable";
import StockReportCsv from "./StockReportCsv";
import Spinner from "../utils/Spinner";
import stockReportIcon from "../../logo/stockReport.png";
import Search from '../utils/Search'

const messages = {
  label: {
    report: "Stock On Hand And Throughput Report",
    sku: "SKU",
    goBack: "Home",
    name: "NAME",
    usage: "Usage in Period",
    execute: "Execute Report",
    Search: "Search By",
    csv: "Download To CSV",
    startDate: "Start Date for Throughput",
    endDate: "End Date for Throughput",
  },
};

const StockReport = ({ match }) => {
  let history = useHistory();
  const {
    setCurrentClientUpdateData,
    allStocks,
    stock,
    client,
    loading,
    clearStock,
    getProductManager,
    stockReportPagination,
    stocReportPaginationAndSearchingState,
    getProductManagerWithCLientWarehouse,
    productManagerWithWarehouse
  } = useContext(EssityContext);


  let [stockReportState, setStockReportState] = useState({
    searchField: stockReportPagination?.searchField ? stockReportPagination?.searchField : "_",
    searchValue: stockReportPagination?.searchValue ? stockReportPagination?.searchValue : "",
    searchingOption: ["sku", "name", "product manager"],
    dateFrom: "",
    dateTo: moment().format("YYYY-MM-DD"),
    productManager: [],
    searchProductManager: "",
    sortBy: "itemcode",
    limit: stockReportPagination?.limit ? stockReportPagination?.limit : 20,
    page: stockReportPagination?.page ? stockReportPagination?.page : 1,
    orderBy: "desc",
    limitOption: [20, 50, "All"],
    serachStockReportNotBySearchValue:true
  });

  const setSearchingValues = (value) => {
    stockReportState = { ...stockReportState, searchField: value?.searchField, searchValue: value?.searchValue, page: 1, serachStockReportNotBySearchValue: false }
    setStockReportState({
      ...stockReportState,
      searchField: value?.searchField,
      searchValue: value?.searchValue,
      page: 1,
      serachStockReportNotBySearchValue: false
    })
  }

  const onChange = (field, value) => {
    setStockReportState({
      ...stockReportState,
      [field]: value,
    });
    if (field === "searchField" && value === "_") {
      stockReportState.searchField = "";
      stockReportState.searchValue = "";
      getSearchValue();
    }
  };
  const setChangeLimit = (value)=>{
    setStockReportState({
      ...stockReportState,
      limit:value,
      page:1
    })
  }

  const changeOrderByAndItemBy = (itemBy)=>{
    setStockReportState({
      ...stockReportState,
      orderBy: stockReportState?.orderBy == "asc" ? "desc" :"asc",
      sortBy:itemBy
    });
  }

  useEffect(async () => {
    await setCurrentClientUpdateData(match.params.essity, history);
  }, [])
  const clientWarehouse = JSON.parse(localStorage.getItem("client")).warehouse;

  useEffect(async () => {
    await stocReportPaginationAndSearchingState(stockReportState?.page, stockReportState?.limit, stockReportState?.searchField, stockReportState?.searchValue)
  }, [stockReportState.page, stockReportState.limit, stockReportState?.searchField, stockReportState?.searchValue])

  useEffect(async () => {
    if (!_.isEmpty(clientWarehouse)) {
      await getProductManagerWithCLientWarehouse(clientWarehouse);
    }
  }, [
    // clientWarehouse
  ]);
  useEffect(async () => {
    if(stockReportState?.serachStockReportNotBySearchValue){
    await getSearchValue();
    }
  }, [
    stockReportState?.page,
    stockReportState?.limit,
    clientWarehouse,
    stockReportState?.dateFrom,
    stockReportState?.dateTo,
    stockReportState.sortBy,
    stockReportState?.orderBy
  ]);

  const goBackToHome = async () => {
    await stocReportPaginationAndSearchingState("", stockReportPagination?.limit, "", "");
    clearStock()
  }


  const getSearchValue = async (setPageNumberOneIfWeSearchData) => {
    let { searchField, searchValue, dateFrom, dateTo, sortBy, limit, page, orderBy } = stockReportState;
    page = setPageNumberOneIfWeSearchData ? 1 : page
    searchField = searchField === "_" ? "" : searchField;
    
    await allStocks(
      clientWarehouse,
      limit,
      page,
      searchField === "product manager" ? "ADDITIONALFIELD_2" : searchField,
      searchValue,
      dateFrom === "" ? moment().add(-60, 'days').format("YYYY-MM-DD") : dateFrom,
      dateTo,
      sortBy,
      orderBy
    );

    
    stockReportState = { ...stockReportState, serachStockReportNotBySearchValue: true }
    setStockReportState({
      ...stockReportState,
      serachStockReportNotBySearchValue: true
    })


  };

  return (
    <>


      <Navbar
        logout={true}
        logo={client?.photo || null}
        name={match.params.essity}
      ></Navbar>
      <div className="stockrepot">
        <div className="container-fluid px-5 ">
          <h1 className=" display-5">
            {messages.label.report}
            <img
              src={stockReportIcon}
              alt="stockReportIcon"
              className="iconOnCustomerSide"
            />
          </h1>
          <Link
            to={`/${match.params.essity}/dashboard`}
            className="btn button mb-1"
            onClick={async () => await goBackToHome()}
          >
            {messages.label.goBack}
          </Link>
          <>
            <div className="row">
              <div className="col-md-4">
                <div className="form-group">
                  <label style={{ fontWeight: "bold" }}>
                    {messages.label.startDate}
                  </label>
                  <input
                    type="date"
                    onChange={(e) => onChange("dateFrom", e.target.value)}
                    className="form-control"
                    value={stockReportState?.dateFrom}
                    max={moment().subtract(1, "day").format("YYYY-MM-DD")}
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                  // defaultValue="null"
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label style={{ fontWeight: "bold" }}>
                    {messages.label.endDate}
                  </label>
                  <input
                    type="date"
                    onChange={(e) => onChange("dateTo", e.target.value)}
                    className="form-control"
                    value={stockReportState?.dateTo}
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                  />
                </div>
              </div>
              <div className="col-md-4">
                {/* <button
                  className="btn button btn-block"
                  style={{ marginTop: "33px" }}
                > */}
                <StockReportCsv
                  stock={stock.stock}
                  label={messages.label.csv}
                />
                {/* </button> */}
              </div>
            </div>
            
            <Search
            getSearchValueFromTable={getSearchValue}
            setSearchingValues={setSearchingValues}
            serachOption={stockReportState?.searchingOption}
            searchField={stockReportState?.searchField}
            searchValue={stockReportState?.searchValue}
            productManagerWithWarehouse={productManagerWithWarehouse && productManagerWithWarehouse}
            />

            {/* <div className="row">
              <div className="col-md-6">
                <select
                  className="form-control selectList"
                  onChange={(e) => onChange("searchField", e.target.value)}
                  value={stockReportState?.searchField}
                >
                  <option value="_">Find By</option>
                  {stockReportState?.searchingOption?.map((response) => (
                    <option value={response} key={response}>
                      {response.toLocaleUpperCase()}
                    </option>
                  ))}
                </select>
              </div>
              {stockReportState?.searchField !== "_" && (
                <div className="col-md-6">
                  {stockReportState?.searchField === "product manager" ? (
                    <div>
                      <div className="input-group">
                        <select
                          className="form-control"
                          onChange={(e) =>
                            onChange("searchValue", e.target.value)
                          }
                          value={stockReportState?.searchValue}

                        >
                          <option value="">Search All</option>
                          {productManagerWithWarehouse &&
                            productManagerWithWarehouse?.map((manager) => (
                              <option key={manager} value={manager}>
                                {manager}
                              </option>
                            ))}
                        </select>
                        <div className="input-group-append selectList">
                          <span
                            className="input-group-text selectList "
                            id="basic-addon2"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              getSearchValue(true)
                            }}
                          >
                            <i className="fas fa-search"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={`Search All ${stockReportState?.searchField.toLocaleUpperCase()}`}
                          aria-describedby="basic-addon2"
                          onChange={(e) =>
                            onChange("searchValue", e.target.value)
                          }
                          value={stockReportState?.searchValue}

                        />
                        <div className="input-group-append">
                          <span
                            className="input-group-text selectList"
                            id="basic-addon2"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              getSearchValue(true)
                            }}
                          >
                            <i className="fas fa-search"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div> */}

            
            {loading && _.isEmpty(stock) ? <Spinner /> :
            <div>
              {loading && <Spinner />}
            
              <StockReportTable
              limitOption={stockReportState?.limitOption}
              limit = {stockReportState?.limit}
              page={stockReportState?.page}
                stockList={stock.stock || []}
                totalPages={stock.totalPages}
                loading={loading}
                onChange={onChange}
                changeOrderByAndItemBy={changeOrderByAndItemBy}
                setChangeLimit={setChangeLimit}
                // stockReportPagination={stockReportPagination}
              />
              </div>
            }
          </>
        </div>
      </div>



    </>
  );
};

export default StockReport;
