import React, { useState, useRef } from "react";
import classnames from "classnames";
const messages = {
  label: {
    details: "Your Details",
    name: "Name",
    organization: "Organization",
    town: "Town",
    state: "State",
    postCode: "Post Code",
    address1: "Address 1",
    address2: "Address 2",
    phone: "Phone",
    email: "Email",
    password: "Password",
    login: "Login",
    userStatus: "User Status",
    active: "Active",
    inactive: "Inactive",
  },
};
const UserDetials = ({
  changeInput,
  name,
  organization,
  login,
  phone,
  email,
  password,
  address1,
  address2,
  town,
  state,
  postcode,
  userStatus,
  error,
}) => {
  const [eye, setEye] = useState("fa fa-eye-slash");
  const passInput = useRef(null);
  const seePassword = (e) => {
    if (passInput.current.type == "password") {
      passInput.current.type = "text";
      setEye("fa fa-eye");
    } else {
      passInput.current.type = "password";
      setEye("fa fa-eye-slash");
    }
  };
  return (
    <div className="card mb-4 border-dark">
      <div className="card-header text-center clientCardHeader ">
        <h5>
          <a
            href="#collapse1"
            data-parent="#accordion"
            data-toggle="collapse"
            className="text-white"
          >
            {messages.label.details}
          </a>
        </h5>
      </div>

      <div id="collapse1" className="collapse show">
        <div className="card-body">
          <div className="row">
            <div className="col-md-4">
              <div className="form-group row">
                <label htmlFor="" className="col-lg-4 col-form-label label ">
                  {messages.label.name}
                </label>
                <div className="col-lg-8 px-0 px-md-3 px-sm-3">
                  <input
                    disabled
                    type="text"
                    className={classnames("form-control", {
                      "is-invalid": error.name,
                    })}
                    value={name}
                    name="name"
                    onChange={(e) => changeInput("name", e.target.value)}
                  />
                  <small>{error.name}</small>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group row">
                <label htmlFor="" className="col-lg-4 col-form-label label ">
                  {messages.label.organization}
                </label>
                <div className="col-lg-8 px-0 px-md-3 px-sm-3">
                  <input
                    type="text"
                    className={classnames("form-control", {
                      "is-invalid": error.organization,
                    })}
                    value={organization}
                    onChange={(e) =>
                      changeInput("organization", e.target.value)
                    }
                  />
                  <small>{error.organization}</small>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group row">
                <label htmlFor="" className="col-lg-4 col-form-label label ">
                  {messages.label.login}
                </label>
                <div className="col-lg-8 px-0 px-md-3 px-sm-3">
                  <input
                    type="text"
                    className="form-control"
                    value={login}
                    disabled
                    onChange={(e) => changeInput("login", e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <div className="form-group row">
                <label htmlFor="" className="col-lg-4 col-form-label label ">
                  {messages.label.phone}
                </label>
                <div className="col-lg-8 px-0 px-md-3 px-sm-3">
                  <input
                    type="number"
                    className={classnames("form-control", {
                      "is-invalid": error.phone,
                    })}
                    value={phone}
                    onKeyDown={(event) => {
                      const characterCode = event.key;
                      if (characterCode === "Backspace") return;

                      const characterNumber = Number(characterCode);
                      if (characterNumber >= 0 && characterNumber <= 9) {
                        if (
                          event.currentTarget.value &&
                          event.currentTarget.value.length
                        ) {
                          return;
                        }
                      } else {
                        event.preventDefault();
                      }
                    }}
                    onChange={(event) =>
                      changeInput("phone", event.target.value)
                    }
                  />
                  <small>{error.phone}</small>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group row">
                <label htmlFor="" className="col-lg-4 col-form-label label ">
                  {messages.label.email}
                </label>
                <div className="col-lg-8 px-0 px-md-3 px-sm-3">
                  <input
                    type="email"
                    className={classnames("form-control", {
                      "is-invalid": error.email,
                    })}
                    value={email}
                    onChange={(event) =>
                      changeInput("email", event.target.value)
                    }
                  />
                  <small>{error.email}</small>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group row">
                <label htmlFor="" className="col-lg-4 col-form-label label ">
                  {messages.label.password}
                </label>
                <div className="col-lg-8 px-0 px-md-3 px-sm-3">
                  <div className="input-group">
                    <input
                      type="password"
                      className={classnames("form-control ", {
                        "is-invalid": error.password,
                      })}
                      aria-label="Recipient's username"
                      aria-describedby="show_hide_password"
                      id="password"
                      ref={passInput}
                      value={password}
                      onChange={(event) =>
                        changeInput("password", event.target.value)
                      }
                    />
                    <div className="input-group-append ">
                      <span
                        className="input-group-text selectList"
                        id="show_hide_password"
                        style={{ cursor: "pointer" }}
                        onClick={seePassword}
                      >
                        <i className={eye} aria-hidden="true"></i>
                      </span>
                    </div>
                  </div>

                  <small>{error.password}</small>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <div className="form-group row ">
                <label
                  htmlFor="name"
                  className="col-lg-4 col-form-label label "
                >
                  {messages.label.address1}
                </label>
                <div className="col-lg-8 px-0 px-md-3 px-sm-3">
                  <input
                    type="text"
                    value={address1}
                    onChange={(e) => changeInput("address1", e.target.value)}
                    className={classnames("form-control wone ", {
                      "is-invalid": error.address1,
                    })}
                  />
                  <small>{error.address1}</small>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              <div className="form-group row ">
                <label
                  htmlFor="name"
                  className="col-lg-4 col-form-label label "
                >
                  {messages.label.address2}
                </label>
                <div className="col-lg-8 px-0 px-md-3 px-sm-3">
                  <input
                    type="text"
                    value={address2}
                    className={classnames("form-control wone ", {
                      "is-invalid": error.address2,
                    })}
                    onChange={(event) =>
                      changeInput("address2", event.target.value)
                    }
                  />
                  <small>{error.address2}</small>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="form-group row">
                <label htmlFor="" className="col-lg-4 col-form-label label ">
                  {messages.label.town}{" "}
                </label>
                <div className="col-lg-8 px-0 px-md-3 px-sm-3">
                  <input
                    type="text"
                    className={classnames("form-control", {
                      "is-invalid": error.town,
                    })}
                    value={town}
                    onChange={(event) =>
                      changeInput("town", event.target.value)
                    }
                  />
                  <small>{error.town}</small>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group row">
                <label htmlFor="" className="col-lg-4 col-form-label label ">
                  {messages.label.state}
                </label>
                <div className="col-lg-8 px-0 px-md-3 px-sm-3">
                  <input
                    type="text"
                    className={classnames("form-control", {
                      "is-invalid": error.state,
                    })}
                    value={state}
                    onChange={(event) =>
                      changeInput("state", event.target.value)
                    }
                  />
                  <small>{error.state}</small>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group row">
                <label htmlFor="" className="col-lg-4 col-form-label label ">
                  {messages.label.postCode}
                </label>
                <div className="col-lg-8 px-0 px-md-3 px-sm-3">
                  <input
                    value={postcode}
                    onKeyDown={(event) => {
                      const characterCode = event.key;
                      if (characterCode === "Backspace") return;

                      const characterNumber = Number(characterCode);
                      if (characterNumber >= 0 && characterNumber <= 9) {
                        if (
                          event.currentTarget.value &&
                          event.currentTarget.value.length
                        ) {
                          return;
                        }
                      } else {
                        event.preventDefault();
                      }
                    }}
                    onChange={(event) =>
                      changeInput("postcode", event.target.value)
                    }
                    type="number"
                    className={classnames("form-control", {
                      "is-invalid": error.postcode,
                    })}
                  />
                  <small>{error.postcode}</small>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="form-group row">
                <label
                  htmlFor="name"
                  className="col-lg-4 col-form-label label "
                >
                  {messages.label.userStatus}
                </label>
                <div className="col-lg-8 px-0 px-md-3 px-sm-3">
                  <select
                    disabled
                    className={classnames("form-control selectList", {
                      "is-invalid": error.userstatus,
                    })}
                    onChange={(event) =>
                      changeInput("status", event.target.value)
                    }
                  >
                    <option
                      value="active"
                      selected={userStatus === "active" ? true : false}
                    >
                      {messages.label.active}
                    </option>
                    <option
                      value="inactive"
                      selected={userStatus === "inactive" ? true : false}
                    >
                      {messages.label.inactive}
                    </option>
                  </select>
                  <small>{error.userstatus}</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetials;
