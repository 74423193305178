import React from "react";
import ReactPaginate from "react-paginate";
import { isUndefined } from "lodash";

const PaginationServerSide = ({ callBack, total,initialPage=0 }) => {
  const setPage = (page) => {
    page = page.selected;
    if(page< total){
    callBack(parseInt(page + 1));
    }
  };

  return (
    !isUndefined(total) && (
      <ReactPaginate
        previousLabel={"prev"}
        nextLabel={"next"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={total}
        marginPagesDisplayed={2}
        pageRangeDisplayed={1}
        onPageChange={setPage}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
        initialPage={initialPage}
        forcePage={initialPage}
      />
    )
  );
};

export default PaginationServerSide;
