import React from "react";
import classnames from "classnames";

const messages = {
  label: {
    password: "Password",
    login: "Login",
    or: "OR",
    email: "Email",
  },
  button: {
    login: "Login",
    continue: "Continue",
  },
};

const CartLoginForm = ({ setEmail, setPassword, onSubmit, error, user }) => {
  return (
    <div
      className="modal fade"
      id="modalLoginForm"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header text-center">
            <h4 className="modal-title w-100 font-weight-bold">Sign in</h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              id="close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body mx-3 cartLoginForm">
            <form onSubmit={onSubmit}>
              <div className="form-group ">
                <label htmlFor="email" className="label">
                  {messages.label.login}
                </label>
                <input
                  type="text"
                  className={classnames("form-control ", {
                    "is-invalid": error?.login,
                  })}
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <small> {error?.login} </small>
              </div>
              <div className="form-group">
                <label htmlFor="password" className="label">
                  {messages.label.password}
                </label>
                <input
                  type="password"
                  className={classnames("form-control ", {
                    "is-invalid": error?.password,
                  })}
                  name="password"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <small> {error?.password} </small>
                {JSON.stringify(user) !== JSON.stringify({}) ? (
                  <button
                    type="button"
                    className="btn btn-secondary m-1 d-block"
                    data-dismiss="modal"
                  >
                    {messages.button.continue}
                  </button>
                ) : (
                  <input
                    type="submit"
                    value={messages.button.login}
                    id="loginBtn"
                    className="btn btn-success m-1 d-block"
                  />
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartLoginForm;
