import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const ALerts = ({ msg, type }) => {
  let [show, setShow] = useState(false);

  useEffect(() => {
    if (msg !== "") {
      setShow(true);
      type == "info"
        ? toast.dark(msg, {
            position: "bottom-right",
            autoClose: 10000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          })
        : toast.dark(msg, {
            position: "bottom-right",
            autoClose: 10000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
    } else {
      setShow(false);
    }
  }, []);
  return show ? (
    <div>
      {msg !== "" ? (
        <div>
          <ToastContainer />
        </div>
      ) : (
        ""
      )}
    </div>
  ) : (
    ""
  );
};
ALerts.defaultProps = {
  type: "info",
};
export default ALerts;
