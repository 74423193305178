import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isClient } from "../utils/clientStatus";

const PrivateRouteClient = ({ component: Component, ...rest }) => {
  let clientName = localStorage.getItem("clientname");

  return (
    <Route
      {...rest}
      render={(props) =>
        isClient() ? (
          <Component {...props}></Component>
        ) : (
          <Redirect to={`/${clientName}`}></Redirect>
        )
      }
    ></Route>
  );
};

export default PrivateRouteClient;
