import React, { useState, useEffect, useContext } from "react";
import Navbar from "../layouts/Navbar";
import { Link, useLocation, useHistory } from "react-router-dom";
import EssityContext from "../../context/EssityContext";
import DeliveryForm from "./DeliveryForm";
import Spinner from "../utils/Spinner";

const messages = {
  label: {
    goBack: "Go Back",
    delivery: "Delivery",
    cancel: "Cancel Order",
  },
};

const Delivery = ({ match }) => {
  const location = useLocation();
  const history = useHistory();
  const {
    setCurrentClientUpdateData,
    user,
    addOrder,
    client,
    removeError,
    error,
    cancelOrder,
    loading,
  } = useContext(EssityContext);

  const [userDefinitionState, setUserDefinitionState] = useState({
    name: "",
    email: "",
    organization: "",
    phone: "",
    dname: "",
    demail: "",
    dphone: "",
    daddress1: "",
    daddress2: "",
    dpostcode: "",
    dstate: "",
    dtown: "",
    dorganization: "",
    isTermAccepted: false,
  });

  const setCurrentUserAndDeliveryDetails = () => {
    let userState = { ...user };
    let delivery = { ...user.delivery };
    setUserDefinitionState({
      ...userDefinitionState,
      name: userState.name,
      email: userState.email,
      phone: userState.phone,
      organization: userState.organization,
      dname: delivery.name,
      dorganization: delivery.organization,
      dtown: delivery.town,
      dstate: delivery.state,
      dpostcode: delivery.postcode,
      daddress1: delivery.address1,
      daddress2: delivery.address2,
      dphone: delivery.phone,
      demail: delivery.email,
      isTermAccepted: user.isTermAccepted,
    });
  };

  useEffect(() => {
    setCurrentClientUpdateData(match.params.essity, history);
    if (user) {
      setCurrentUserAndDeliveryDetails();
    }
    return;
  }, [location, user]);
  const changeUserTerm = (value) => {
    // console.log(value);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const cardItem = localStorage.getItem("card");
    const temp = JSON.parse(cardItem);
    const client = JSON.parse(localStorage.client);
    const orderData = {
      userPersonalDetail: {
        name: userDefinitionState.name,
        organization: userDefinitionState.organization,
        email: userDefinitionState.email,
        phoneNumber: userDefinitionState.phone,
      },
      isTermAccepted: userDefinitionState.isTermAccepted,
      userDelivaryDetail: {
        name: userDefinitionState.dname,
        organization: userDefinitionState.dorganization,
        town: userDefinitionState.dtown,
        state: userDefinitionState.dstate,
        postcode: userDefinitionState.dpostcode,
        address1: userDefinitionState.daddress1,
        address2: userDefinitionState.daddress2,
        phoneNumber: userDefinitionState.dphone,
        email: userDefinitionState.demail,
      },
      lineItems: temp,
      deliveryFee: client.isDeliveryEnabled ? client.delivery : 0,
      clientName: client.name,
      clientId: client._id,
      totalPrice: localStorage.getItem("total")
        ? localStorage.getItem("total")
        : 0,
    };
    addOrder(
      orderData,
      user._id,
      history,
      match,
      client.payment ? true : false,
      user.isTermAccepted
    );
  };

  const changeInput = (inputName, inputValue) => {
    setUserDefinitionState({
      ...userDefinitionState,
      [inputName]: inputValue,
    });
  };
  const orderCancel = () => {
    removeError();
    cancelOrder(history, match);
  };

  return (
    <div>
      <Navbar
        logout={true}
        cart={true}
        name={match.params.essity}
        logo={client !== undefined && client.photo ? client.photo : ""}
      ></Navbar>
      {loading && <Spinner />}
      <div className="delivery" style={{ marginTop: "10px" }}>
        <div className="container-fluid px-5">
          <h1 className=" display-4 mb-4">{messages.label.delivery}</h1>
          <Link
            className="btn btn-primary mb-2 "
            to={`/${match.params.essity}/cart`}
            onClick={() => removeError()}
          >
            {messages.label.goBack}
          </Link>
          <button className="btn btn-danger mb-2 ml-2" onClick={orderCancel}>
            {messages.label.cancel}
          </button>
          <div className="row">
            <div className="col-md-12 py-5">
              <DeliveryForm
                onSubmit={onSubmit}
                loading={loading}
                changeInput={changeInput}
                error={error}
                name={userDefinitionState.name}
                email={userDefinitionState.email}
                phone={userDefinitionState.phone}
                dname={userDefinitionState.dname}
                dtown={userDefinitionState.dtown}
                dphone={userDefinitionState.dphone}
                dstate={userDefinitionState.dstate}
                demail={userDefinitionState.demail}
                daddress2={userDefinitionState.daddress2}
                daddress1={userDefinitionState.daddress1}
                dpostcode={userDefinitionState.dpostcode}
                organization={userDefinitionState.organization}
                dorganization={userDefinitionState.dorganization}
                isTermAccepted={userDefinitionState.isTermAccepted}
                isTermAcceptedCurrent={user && user.isTermAccepted}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Delivery;
