import React from "react";
import classnames from "classnames";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";

const messages = {
  label: {
    cardNumber: "Card Number",
    cardExpiry: "Card Expiry",
    card: "Card CVC",
    pay: "Pay",
  },
};
const StripePaymentForm = ({ handleSubmit, error, stripe }) => {
  const CARD_OPTIONS = {
    iconStyle: "solid",
    style: {
      base: {
        iconColor: "#c4f0ff",
        color: "#333",
        fontWeight: 500,
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": { color: "#fce883" },
        "::placeholder": { color: "#87bbfd" },
      },
      invalid: {
        iconColor: "red",
        color: "red",
      },
    },
  };
  return (
    <form onSubmit={handleSubmit} className="stripeForm">
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="cardNumber" className="label">
              {messages.label.cardNumber}
            </label>
            <div
              className={classnames("j", {
                "payment-error":
                  error?.code === "incomplete_number" || error?.stripe,
              })}
            >
              <CardNumberElement
                options={CARD_OPTIONS}
                className="form-control payment-input "
                required
              ></CardNumberElement>
            </div>
            <small>
              {error?.code === "incomplete_number"
                ? "Your card number is incomplete."
                : error?.stripe
                ? error.stripe
                : ""}
            </small>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="card" className="label">
              {messages.label.cardExpiry}
            </label>
            <div
              className={classnames("j", {
                "payment-error":
                  error?.code === "incomplete_expiry" ||
                  error?.code === "invalid_expiry_year_past" ||
                  error?.code === "invalid_expiry_month_past",
              })}
            >
              <CardExpiryElement
                options={CARD_OPTIONS}
                className="form-control payment-input "
              ></CardExpiryElement>
            </div>
            <small>
              {(error?.code === "incomplete_expiry" ||
                error?.code === "invalid_expiry_year_past" ||
                error?.code === "invalid_expiry_month_past") &&
                error?.message}
            </small>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group">
            <label htmlFor="card" className="label">
              {messages.label.card}
            </label>
            <div
              className={classnames("j", {
                "payment-error": error?.code === "incomplete_cvc",
              })}
            >
              <CardCvcElement
                options={CARD_OPTIONS}
                className="form-control payment-input "
              ></CardCvcElement>
            </div>
            <small>{error?.code === "incomplete_cvc" && error.message}</small>
          </div>
        </div>
      </div>

      <button type="submit" disabled={!stripe} className="btn btn-info">
        {messages.label.pay}
      </button>
    </form>
  );
};

export default StripePaymentForm;
