import React, { useState, useEffect, useContext } from "react";
import { find, isEmpty } from "lodash";
import _ from "lodash";
import Navbar from "../layouts/Navbar";
import { Link, useHistory } from "react-router-dom";
import EssityContext from "../../context/EssityContext";
import OrderReviewCard from "./OrderReviewCard";
import PaginationServerSide from "../utils/PaginationServerSide";
import Alerts from "../utils/Alerts";
import ALerts from "../utils/Alerts";
import Spinner from "../utils/Spinner";
import orderApproveIcon from "../../logo/approveOrder.png";
const messages = {
  label: {
    review: "Approve Orders",
    goBack: "Home",
    show: "Show",
    entries: "entries",
  },
};

const OrderReview = ({ match }) => {
  let history = useHistory();
  let {
    getOrders,
    orders,
    updateOrder,
    setCurrentClientUpdateData,
    msg,
    clearMsg,
    client,
    loading,
    clearOrder,
    setMsg,
    syncOrder,
    orderPaginationAndSearchingState,
    orderPagination
  } = useContext(EssityContext);
  const [isOrderSync, setIsOrderSync] = useState(true);
  const [isDraft, setIsDraft] = useState(false);
  const [limit, setLimit] = useState(orderPagination?.limit ? orderPagination?.limit : 20);
  const limitOption = [20, 50, "All"];
  const [page, setPage] = useState(orderPagination?.page ? orderPagination?.page : 1);
  let [temporaryOrder, setTemporaryOrder] = useState([]);
  let [updateUI, setUpdateUI] = useState(false);
  const clientName = client && client?.name;

  const callbackFunctionFromPagination = (page) => {
    setPage(parseInt(page));
  };
  useEffect(async () => {
    await getOrders(limit, page);
  }, []);

  useEffect(() => {
    setCurrentClientUpdateData(match.params.essity, history);
  }, [limit, page, isOrderSync]);

  useEffect(async()=>{
    await orderPaginationAndSearchingState(page, limit)
  },[page,limit])

  const setChangeLimit = (e) => {
    setLimit(e.target.value);
    setPage(1);
  };

  const updateAndSubmit = async (order, _id) => {
    let checkOrderLineItemsHasNoDarft = isEmpty(
      find(order.linesItems, ["status", "requested"])
    )
      ? false
      : true;
    if (checkOrderLineItemsHasNoDarft) {
      setIsDraft(true);

      // setMsg("Please approve or cancel all Items");
      setTimeout(() => {
        setIsDraft(false);
        clearMsg();
      }, 4000);
    } else {
      let linesItems = order.linesItems;
      await updateOrder(linesItems, _id, history, match.params.essity);
      let findIndex = orders.orders.findIndex((order) => order._id == _id);
      if (findIndex >= 0) {
        let currentOrder = orders.orders[findIndex];
        currentOrder.status = "Reviewed";
        orders.orders[findIndex] = currentOrder;
      }
      orders.orders = temporaryOrder.filter(res => res._id !== _id);
    }
    setIsOrderSync(!isOrderSync);
  };

  const changeAllLineItemStatus = (orderId, value) => {
    let orderUpdate = orders.orders.find((res) => res._id === orderId);
    if (!_.isEmpty(orderUpdate)) {
      for (let i in orderUpdate.linesItems) {
        orderUpdate.linesItems[i].status = value;
      }
      let orderUpdateIndex = orders.orders.findIndex(
        (res) => res._id === orderId
      );
      temporaryOrder[orderUpdateIndex] = orderUpdate;

      setTemporaryOrder({
        temporaryOrder,
      });

    }
  };

  const changeLineItemStatus = (orderId, lineItemId, newStatus) => {
    let orderUpdate = orders.orders.find((res) => res._id === orderId);
    if (!_.isEmpty(orderUpdate)) {

      for (let lineItem of orderUpdate.linesItems) {
        // console.log(lineItem, lineItemId);
        if (lineItem.id === lineItemId) {
          lineItem.status = newStatus
        }
      }
      let orderUpdateIndex = orders.orders.findIndex(
        (res) => res._id === orderId
      );
      temporaryOrder[orderUpdateIndex] = orderUpdate;

      setTemporaryOrder({
        temporaryOrder,
      });

    }
  };

  const goBackToHome = async ()=>{
    await orderPaginationAndSearchingState("", orderPagination?.limit)
    clearMsg();
                  localStorage.removeItem("orders");
                  clearOrder();
  }

  temporaryOrder = orders.orders || [];
  return (
    <div>
      <Navbar
        logout={true}
        name={match.params.essity}
        logo={client !== undefined && client.photo ? client.photo : ""}
      ></Navbar>
      <div className="orderreview">
        <div className="container-fluid px-5">
          <h1 className="display-4">
            {messages.label.review}

            <img
              src={orderApproveIcon}
              alt="orderApproveIcon"
              className="iconOnCustomerSide"
              style={{ marginTop: "0px" }}
            />
          </h1>
          <div className="table-show-limit">
            <div>
              <Link
                to={`/${match.params.essity}/dashboard`}
                className="btn button "
                onClick={async (e) => {
                  // clearMsg();
                  // localStorage.removeItem("orders");
                  // clearOrder();
                 await goBackToHome()
                }}
              >
                {messages.label.goBack}
              </Link>
            </div>
            <div className="show-limit ">
              <div className="show-limit-inner">
                <p className="pt-3" style={{ fontWeight: "bold" }}>
                  {messages.label.show}
                </p>
                <select
                  name="range"
                  className="form-control mx-1 selectList"
                  style={{ width: "70px" }}
                  onChange={setChangeLimit}
                  value={limit}
                >
                  {limitOption.map((limitValue) => (
                    <option key={limitValue} value={limitValue}>
                      {limitValue}
                    </option>
                  ))}
                </select>
                <p className="pt-3" style={{ fontWeight: "bold" }}>
                  {messages.label.entries}
                </p>
              </div>
            </div>
          </div>
          {msg && <Alerts msg={msg} />}
          {isDraft && (
            <ALerts
              msg="Please approve or cancel all Items"
              type="error"
            ></ALerts>
          )}
          {loading && isEmpty(orders) ? (
            <Spinner />
          ) : (
            <div>
              <>
                {loading && <Spinner />}
                <OrderReviewCard
                  loading={loading}
                  updateAndSubmit={updateAndSubmit}
                  orders={temporaryOrder || []}
                  syncOrder={syncOrder}
                  isOrderSync={isOrderSync}
                  setIsOrderSync={setIsOrderSync}
                  changeAllLineItemStatus={changeAllLineItemStatus}
                  changeLineItemStatus={changeLineItemStatus}
                />
              </>
              <PaginationServerSide
                callBack={callbackFunctionFromPagination}
                total={orders.totalPages}
                initialPage={page-1}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default OrderReview;
