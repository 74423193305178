import React from "react";
import { useHistory } from "react-router-dom";

const SingleCategorie = ({ category: { _id, name, image }, match ,searchCategoryValue,clearSearchFiledAndValueOfCategorySku}) => {
  const history = useHistory();
  const categoryImage =
    image !== "no-image.png"
      ? `${process.env.REACT_APP_BACKEND_URL}/uploads/category/${image}`
      : "https://i.pinimg.com/originals/7c/cb/01/7ccb010d8fddc4bcd84587ef3c34d100.jpg";

  const goToCategory = async () => {
   await clearSearchFiledAndValueOfCategorySku();
    localStorage.setItem("currentCategory", JSON.stringify({ name, image }));
    history.push({
      pathname: `/${match}/categories/${_id}`,
      state: { categoryname: name, image: categoryImage,categorySkusAndDiscriptionFilter:false },
    });
  };

  return (
    <div className="singleCategory">
      <button onClick={goToCategory}>
        <div>
          <img src={categoryImage} className="" />
        </div>
        <h4 className="text-center m-2">{name}</h4>
      </button>
    </div>
  );
};

export default SingleCategorie;
