import React, { useState, useContext, useEffect } from "react";
import classnames from "classnames";
import { Link, useHistory } from "react-router-dom";
import EssityContext from "../../context/EssityContext";

const messages = {
  label: {
    accountLogIn: "ACCOUNT LOG IN",
    password: "Password",
    login: "Login",
    or: "OR",
    // isTermAccepted: "I have read and accept Modern Promotions'",
    // privacyLink: "privacy policy",
  },
  button: {
    guest: "Continue as a Guest",
    login: "Login",
  },
};


const Login = ({ match, clients }) => {
  const history = useHistory();
  const { error, loginUser, client, setCurrLoggedUser } = useContext(
    EssityContext
  );
  const [loginState, setLoginState] = useState({
    login: "",
    password: "",
    isTermAccepted: false,
  });
  const clientName = client && client?.name;
  useEffect(() => {}, [clientName]);

  const changeLoginState = (field, value) => {
    setLoginState({
      ...loginState,
      [field]: value,
    });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    loginUser(
      loginState.login,
      loginState.password,
      loginState.isTermAccepted,
      history,
      clientName,
      clients,
      match,
      false
    );
  };

  const asGuest = () => {
    localStorage.removeItem("card");
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("total");
    localStorage.removeItem("orders");
    localStorage.setItem("isGuestMode", true);
    setCurrLoggedUser(true);
    history.push(`/${match.params.essity}/dashboard`);
  };

  return (
    <div className="login container" style={{ marginTop: "100px" }}>
      {/* <h1 className="text-center my-4">{messages.label.accountLogIn}</h1> */}
      <form onSubmit={onSubmit}>
        <div className="form-group row ">
          <label htmlFor="email" className="col-sm-4 col-form-label label">
            {messages.label.login}
          </label>
          <div className="col-sm-8  px-0 px-md-3 px-sm-3">
            <input
              type="text"
              name="login"
              className={classnames("form-control", {
                "is-invalid": error?.login,
              })}
              onChange={(e) => changeLoginState("login", e.target.value)}
            />
            <small style={{ color: "#000", fontWeight: "bold" }}>
              {error?.login}
            </small>
          </div>
        </div>

        <div className="form-group row">
          <label htmlFor="email" className="col-sm-4 col-form-label label">
            {messages.label.password}
          </label>
          <div className="col-sm-8  px-0 px-md-3 px-sm-3">
            <input
              type="password"
              name="password"
              className={classnames("form-control ju", {
                "is-invalid": error?.password,
              })}
              onChange={(e) => changeLoginState("password", e.target.value)}
            />
            <small style={{ color: "#000", fontWeight: "bold" }}>
              {error?.password}
            </small>
          </div>
        </div>
        {/* <div className="form-group form-check">
          <input
            className="form-check-input loginCheck"
            type="checkbox"
            checked={loginState.loginCheck}
            onChange={(e) =>
              changeLoginState("isTermAccepted", e.target.checked)
            }
          />
          <label className="form-check-label loginCheckLabel ">
            <strong>
              {messages.label.isTermAccepted}

              <a
                href="https://www.google.com/"
                target="_blank"
                rel="noreferrer"
                className="privacyLinkonLogin"
              >
                {messages.label.privacyLink}
              </a>
            </strong>
          </label>
        </div> */}
        <div>
          <div className="loginButtonOption">
            <div></div>
            <div className="buttons">
              <button type="submit" className="btn btn-secondary  mb-2 button ">
                {messages.button.login}
              </button>
              {clients.guestAccess && (
                <div>
                  {/* <p className="text-center mt-2" style={{ fontWeight: "bold" }}>
                {messages.label.or}
              </p> */}
                  <button onClick={asGuest} className="btn btn-success button">
                    {messages.button.guest}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Login;
