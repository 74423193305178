export const GET_CLIENT = "GET_CLIENT";
export const CLEAR_CLIENT = "CLEAR_CLIENT";
export const LOGIN_USER = "LOGIN_USER";
export const ALL_STOCK = "ALL_STOCK";
export const ALL_CATEGORIES = "ALL_CATEGORIES";
export const ALL_PRODUCTS = "ALL_PRODUCTS";
export const SINGLE_PRODUCT = "SINGLE_PRODUCT";
export const CART_ITEMS = "CARD_ITEMS";
export const CART_ITEM = "CARD_ITEM";
export const TOTAL = "TOTAL";
export const DELETE_ITEM = "DELETE_ITEM";
export const GET_ORDERS = "GET_ORDERS";
export const ADD_ORDERS = "ADD_ORDERS";
export const UPDATE_ORDERS = "UPDATE_ORDERS";
export const ERROR = "ERROR";
export const MSG = "MSG";
export const SET_LOADING = "SET_LOADING";
export const SET_LOADINGFALSE = "SET_LOADINGFALSE";
export const DEL_CARD = "DEL_CARD";
export const PRODUCTMANAGER = "PRODUCTMANAGER";
