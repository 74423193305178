import React, { Fragment } from "react";
import spinner from "./spinner2.gif";
const Spinner = () => {
  return (
    <div className="spinnerDiv">
      <img src={spinner} alt="" className="spinner" />
    </div>
  );
};

export default Spinner;
