import React, { useEffect, useState } from "react";
import PaginationServerSide from "../utils/PaginationServerSide";
import { isEmpty } from "lodash";
import { searchStockByField } from "./index";
import { v4 as uuidv4 } from "uuid";
import Spinner from "../utils/Spinner";

const messages = {
  label: {
    report: "Stock On Hand And throughput Report",
    sku: "SKU",
    goBack: "Go Back",
    name: "Name",
    Usage: "Usage in Period",
    Execute: "Execute Report",
    Search: "Search By",
    csv: "Download To CSV",
    Image: "Image",
    // soh: "SOH",
    unit: "Unit",
    period: "Usage In Period",
    product: "Product Manager",
    stock: "Low Stock Level",
    show: "Show",
    entries: "entries",
    currentStock: "Current Stock",
    availableStock: "Available Stock",
  },
};

const StockReportTable = ({
  limitOption,
  limit,
  page,
  changeOrderByAndItemBy,
  stockList,
  totalPages,
  loading,
  onChange,
  setChangeLimit
}) => {
  

  const callbackFunctionFromPagination = (page) => {
    onChange("page",parseInt(page))
  };

  return (
    <>
      <div className="table-show-limit">
        <div className="show-limit ">
          <div className="show-limit-inner">
            <p className="pt-3" style={{ fontWeight: "bold" }}>
              {messages.label.show}
            </p>
            <select
              name="range"
              className="form-control mx-1 selectList"
              style={{ width: "70px" }}
              onChange={(e)=> setChangeLimit(e.target.value)}
              value={limit}
            >
              {limitOption.map((limitValue) => (
                <option value={limitValue} key={limitValue}>
                  {limitValue}
                </option>
              ))}
            </select>
            <p className="pt-3" style={{ fontWeight: "bold" }}>
              {messages.label.entries}
            </p>
          </div>
        </div>
      </div>

        <>
          <div className="stockTable">
            <div style={{ overflowX: "auto", width: "100%" }}>
              {loading && <Spinner></Spinner>}
              <table className="table table-md" id="sortTable">
                <thead className="thead-dark">
                  <tr>
                    <th className="text-center">
                      <button
                        type="button"
                        style={{ outline: "none" }}
                        onClick={(e) => {
                          changeOrderByAndItemBy("itemcode");
                        }}
                        className="sort-table-btn"
                      >
                        {messages.label.sku}
                      </button>
                    </th>
                    <th className="text-center">
                      <button
                        type="button"
                        style={{ outline: "none" }}
                        onClick={(e) => {
                          changeOrderByAndItemBy("ITEMDESCRIPTION");
                        }}
                        className="sort-table-btn"
                      >
                        {messages.label.name}
                      </button>
                    </th>
                    <th>{messages.label.Image}</th>
                    <th className="text-center">
                      <button
                        type="button"
                        style={{ outline: "none" }}
                        onClick={(e) => {
                          changeOrderByAndItemBy("ONHANDQTY");

                        }}
                        className="sort-table-btn"
                      >
                        {messages.label.currentStock}
                      </button>
                    </th>
                    <th className="text-center">
                      <button
                        type="button"
                        style={{ outline: "none" }}
                        onClick={(e) => {
                          changeOrderByAndItemBy("ITEMUNIT");

                        }}
                        className="sort-table-btn"
                      >
                        {messages.label.unit}
                      </button>
                    </th>
                    <th className="text-center">
                      <button
                        type="button"
                        style={{ outline: "none" }}
                        // onClick={(e) => {
                        //   // sortTable("UsageInPeriod");
                        // }}
                        className="sort-table-btn"
                      >
                        {messages.label.Usage}
                      </button>
                    </th>
                    <th className="text-center">
                      <button
                        type="button"
                        style={{ outline: "none" }}
                        onClick={(e) => {
                          changeOrderByAndItemBy("ADDITIONALFIELD_2");
                        }}
                        className="sort-table-btn"
                      >
                        {messages.label.product}
                      </button>
                    </th>
                    <th className="text-center">
                      <button
                        type="button"
                        style={{ outline: "none" }}
                        onClick={(e) => {
                          changeOrderByAndItemBy("REORDERLEVEL");

                        }}
                        className="sort-table-btn"
                      >
                        {messages.label.stock}
                      </button>
                    </th>
                    <th className="text-center">
                      <button
                        type="button"
                        style={{ outline: "none" }}
                        onClick={(e) => {
                          changeOrderByAndItemBy("FREEQTY");

                        }}
                        className="sort-table-btn"
                      >
                        {messages.label.availableStock}
                      </button>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {stockList &&  stockList.map((stock) => (
                      <tr key={uuidv4()}>
                        {stock.CurrentStock < stock.reorderlevel ? (
                          <td className="text-center" style={{ color: "red" }}>
                            {stock.SKU}
                          </td>
                        ) : (
                          <td className="text-center"> {stock.SKU}</td>
                        )}
                        {stock.CurrentStock < stock.reorderlevel ? (
                          <td className="text-center" style={{ color: "red" }}>
                            {stock.Name}
                          </td>
                        ) : (
                          <td className="text-center"> {stock.Name}</td>
                        )}

                        <td>
                          <img
                            src={stock.image && stock.image[0]}
                            alt=""
                            className="small-logo border"
                          />
                        </td>

                        {stock.CurrentStock < stock.reorderlevel ? (
                          <td className="text-center" style={{ color: "red" }}>
                            {stock.SOH}
                          </td>
                        ) : (
                          <td className="text-center"> {stock.SOH}</td>
                        )}

                        {stock.CurrentStock < stock.reorderlevel ? (
                          <td className="text-center" style={{ color: "red" }}>
                            {stock.Unit}
                          </td>
                        ) : (
                          <td className="text-center">{stock.Unit}</td>
                        )}

                        {stock.CurrentStock < stock.reorderlevel ? (
                          <td className="text-center" style={{ color: "red" }}>
                            {stock.UsageInPeriod}
                          </td>
                        ) : (
                          <td className="text-center">
                            {" "}
                            {stock.UsageInPeriod}
                          </td>
                        )}

                        {stock.CurrentStock < stock.reorderlevel ? (
                          <td className="text-center" style={{ color: "red" }}>
                            {stock.productManager}
                          </td>
                        ) : (
                          <td className="text-center">
                            {" "}
                            {stock.productManager}
                          </td>
                        )}

                        {stock.CurrentStock < stock.reorderlevel ? (
                          <td className="text-center" style={{ color: "red" }}>
                            {stock.reorderlevel}
                          </td>
                        ) : (
                          <td className="text-center"> {stock.reorderlevel}</td>
                        )}

                        {stock.CurrentStock < stock.reorderlevel ? (
                          <td className="text-center" style={{ color: "red" }}>
                            {stock.CurrentStock}
                          </td>
                        ) : (
                          <td className="text-center"> {stock.CurrentStock}</td>
                        )}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
          <PaginationServerSide
            callBack={callbackFunctionFromPagination}
            total={totalPages}
            initialPage={page -1}
          />
        </>
      
    </>
  );
};

export default StockReportTable;
