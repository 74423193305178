import React from "react";

const ProductQuantityInput = ({ item, onChangeQuantity }) => {
  return (
    <input
      type="number"
      placeholder={item.minimumOrder ? item.minimumOrder : 1}
      defaultValue={
        item.currentUnit
          ? item.currentUnit
          : item.minimumOrder
          ? item.minimumOrder
          : 1
      }
      className="form-control product-quan in-w addQuantity"
      min={item.minimumOrder ? item.minimumOrder : 1}
      step={item.roundingNumber ? item.roundingNumber : 1}
      onPaste={(e) => {
        e.preventDefault();
      }}
      onKeyDown={(e) => {
        // for negative number
        if (e.keyCode == 109 || e.keyCode == 189 || e.keyCode == 107 || e.keyCode == 187) {
          e.preventDefault();
        }
      }}
      onChange={onChangeQuantity}
    />
  );
};

export default ProductQuantityInput;
