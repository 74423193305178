import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import moment from "moment";
import classnames from "classnames";
import _ from "lodash";

import OrderReviewTable from "./OrderReviewTable";
const messages = {
  label: {
    reference: "Order Reference No",
    order: "Order Placed By",
    delivery: "For Delivery To",
    date: "Date",
    orderTaker: "Organisation of order taker",
    deliveryAddress: "Organisation of delivery address",
  },
};
const OrderReviewCard = ({
  updateAndSubmit,
  orders,
  loading,
  syncOrder,
  setIsOrderSync,
  isOrderSync,
  changeAllLineItemStatus,
  changeLineItemStatus,
}) => {
  const [comm, setComm] = useState("");
  const [status, setStatus] = useState("");

  useEffect(() => {}, [orders]);

   

  return (
    <div className="orderTable">
      <div id="accordion">
        {orders && 
          orders.map((order) => (
            <div className="card mb-2 " key={order?._id}>
              <div className="card-header text-center clientCardHeader">
                <h5>
                  <a
                    href={`#${order?._id}`}
                    data-parent="#accordion"
                    data-toggle="collapse"
                    className="text-white"
                  >
                    <span className="float-left pt-1">
                      {order?.orderReferenceNo}
                      <span className="d-flex" style={{ fontSize: "0.5rem" }}>
                        Order Number
                      </span>
                    </span>
                    <Moment
                      date={order?.createdAt}
                      format="DD-MM-YYYY"
                    ></Moment>
                    {/* <span
                    className="float-right pt-1"
                    style={{ fontSize: "0.9rem" }}
                  >
                    {order?.status}
                    <span className="d-flex" style={{ fontSize: "0.5rem" }}>
                      Order status
                    </span>
                  </span> */}
                  </a>
                  {order.status == "Submitted" && order.ostendoOrderId == "" ? (
                    <button
                      onClick={async () => {
                        await syncOrder(order._id);
                        setIsOrderSync(!isOrderSync);
                      }}
                      className="btn button ml-0 float-right mr-1"
                    >
                      Sync
                    </button>
                  ) : (
                    <span
                      className="float-right pt-1 text-white"
                      style={{ fontSize: "0.9rem" }}
                    >
                      {order?.status}
                      <span className="d-flex" style={{ fontSize: "0.5rem" }}>
                        Order status
                      </span>
                    </span>
                  )}
                </h5>
              </div>
              <div id={order?._id} className="collapse in">
                <div className="card-body">
                  <div className=" mb-5">
                    <div className="">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-group row">
                                <label className="col-md-4 col-form-label label ">
                                  {messages.label.reference}
                                </label>
                                <div className="col-md-8 px-0 px-md-3 px-sm-3">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={order?.orderReferenceNo}
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group row">
                                <label className="col-md-4 col-form-label label ">
                                  {messages.label.date}
                                </label>
                                <div className="col-md-8 px-0 px-md-3 px-sm-3">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={moment(order?.createdAt).format(
                                      "DD-MM-YYYY"
                                    )}
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group row">
                                <label className="col-md-2 col-form-label label ">
                                  {messages.label.order}
                                </label>
                                <div className="col-md-4 px-0 px-md-3 px-sm-3">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={order?.orderPlacer?.name}
                                    disabled
                                  />
                                </div>
                                <label className="col-md-2 col-form-label label ">
                                  {messages.label.orderTaker}
                                </label>
                                <div className="col-md-4 px-0 px-md-3 px-sm-3">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={order?.orderPlacer?.organization}
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group row">
                                <label className="col-md-2 col-form-label label ">
                                  {messages.label.delivery}
                                </label>
                                <div className="col-md-4 px-0 px-md-3 px-sm-3">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={order?.userDelivaryDetail.name}
                                    disabled
                                  />
                                </div>

                                <label className="col-md-2 col-form-label label ">
                                  {messages.label.deliveryAddress}
                                </label>
                                <div className="col-md-4 px-0 px-md-3 px-sm-3">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={
                                      order?.userDelivaryDetail.organization
                                    }
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <OrderReviewTable
                        loading={loading}
                        setComm={setComm}
                        setStatus={setStatus}
                        order={order}
                        changeAllLineItemStatus={changeAllLineItemStatus}
                        updateAndSubmit={updateAndSubmit}
                        changeLineItemStatus={changeLineItemStatus}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
export default OrderReviewCard;
