import React, { useState, useEffect } from "react";
import { isEmpty } from "lodash";
import moment from "moment";

const Search = ({
  getSearchValueFromTable,
  serachOption,
  searchField,
  searchValue = "",
  setSearchingValues,
  productManagerWithWarehouse,
  productSearching=false
}) => {
  const [searchState, setSearchState] = useState({
    searchField, searchValue
  })

  const onChange = async (field, value) => {
    setSearchState({
      ...searchState,
      [field]: value,
    })
    if (field === "searchField" && value === "_") {
      searchState.searchField = "";
      searchState.searchValue = "";
      setSearchingValues(searchState)
      await getSearchValueFromTable();
    }
  }


  useEffect(() => {
    if (searchState.searchField == "_") {
      setSearchState({
        ...searchState,
        searchValue:"",
      })
    }
  }, [searchState.searchField == "_"]);



  return (
    <div className="row">
    <div className={productSearching ? "col-md-4" : "col-md-6"}>
      <select
        className="form-control selectList"
        onChange={(e) => onChange("searchField", e.target.value)}
        value={searchState?.searchField}
      >
        <option value="_">
            {productSearching ? "Search All": "Find By"}</option>
        {serachOption?.map((response) => (
          <option value={response} key={response}>
            {!productSearching ?response.toLocaleUpperCase(): response}
          </option>
        ))}
      </select>
    </div>
    {searchState?.searchField !== "_" && !isEmpty(searchState.searchField) && (
      <div className={productSearching ? "col-md-4" : "col-md-6"}>
        {searchState?.searchField == "product manager" ? (
          <div>
            <div className="input-group">
              <select
                className="form-control"
                onChange={(e) =>
                  onChange("searchValue", e.target.value)
                }
                value={searchState?.searchValue}

              >
                <option value="">Search All</option>
                {productManagerWithWarehouse &&
                  productManagerWithWarehouse?.map((manager) => (
                    <option key={manager} value={manager}>
                      {manager}
                    </option>
                  ))}
              </select>
              <div className="input-group-append selectList">
                <span
                  className="input-group-text selectList "
                  id="basic-addon2"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setSearchingValues(searchState)
                    getSearchValueFromTable(true)
                  }}
                >
                  <i className="fas fa-search"></i>
                </span>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder={`Search All ${!productSearching ? searchState?.searchField.toLocaleUpperCase() :searchState?.searchField }`}
                aria-describedby="basic-addon2"
                onChange={(e) =>
                  onChange("searchValue", e.target.value)
                }
                value={searchState?.searchValue}

              />
              <div className="input-group-append">
                <span
                  className="input-group-text selectList"
                  id="basic-addon2"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setSearchingValues(searchState);
                    getSearchValueFromTable(true)
                  }}
                >
                  <i className="fas fa-search"></i>
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    )}
  </div>

  );
};

export default Search;
