export const isClient = () => {
  if (localStorage.getItem("client")) {
    let client = JSON.parse(localStorage.getItem("client"));
    if (client.guestAccess || localStorage.getItem("token")) {
      return true;
    }
  } else {
    return false;
  }
};

export const isClientAndUser = () => {
  if (localStorage.getItem("client") && localStorage.getItem("token")) {
    return true;
  } else {
    return false;
  }
};

export const isCategoryEnabled = () => {
  if (
    localStorage.getItem("client") &&
    !JSON.parse(localStorage.getItem("client")).isCategoryEnabled
  ) {
    return true;
  } else {
    return false;
  }
};
