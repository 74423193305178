import React, { useEffect, useRef, useState } from "react";
import ALerts from "../utils/Alerts";
import { orderBy, isEmpty } from "lodash";
import { searchProductByField } from "./index";
import ProductQuantityInput from "./ProductQuantityInput";

const messages = {
  label: {
    sku: "SKU",
    image: "Image",
    name: "Name",
    detail: "Detail",
    price: "Price",
    quantity: "Order Quantity",
    unit: "Unit",
    availableStock: "Available Stock",
  },
};

const ProductTable = ({
  client,
  products,
  onDetial,
  onAdd,
  onUnitChange,
  msg,
  onChangeContainerValue,
  changeOrderBy
}) => {
  const [productState, setProductState] = useState({
    order: "asc",
    products: products,
    searchOption: ["SKU", "Description"],
    searchValue: "",
    searchField: "",
    number: 1,
  });
  const nu = useRef();
  const onChange = (field, value) => {
    setProductState({
      ...productState,
      [field]: value,
    });
  };

  useEffect(() => {
    const { searchField, searchValue } = productState;
    setProductState({
      ...productState,
      products: !isEmpty(products)
        ? searchProductByField(products, searchField, searchValue)
        : [],
    });
  }, [products, productState.searchField, productState.searchValue]);

  const sortTable = (field) => {
    const sortedProducts = [
      ...orderBy(productState.products, [field], [productState.order]),
    ];

    setProductState({
      ...productState,
      products: sortedProducts,
      order: productState.order === "asc" ? "desc" : "asc",
    });
  };
  return (
    <>
      {msg && <ALerts msg={msg} />}

      <div style={{ overflowX: "auto" }}>
        <table className="table table-md" id="myTable">
          <thead className="thead-dark clientCardHeader">
            <tr>
              <th>
                <button
                  type="button"
                  onClick={(e) => {
                    changeOrderBy();
                    // onChangeContainerValue("sortBy", "itemcode");
                    onChangeContainerValue("sortBy", "id");
                  }}
                  className="sort-table-btn"
                >
                  {messages.label.sku}
                </button>
              </th>
              <th>{messages.label.image}</th>
              <th>
                <button
                  type="button"
                  onClick={(e) => {
                    changeOrderBy();
                    // onChangeContainerValue("sortBy", "ITEMDESCRIPTION");
                    onChangeContainerValue("sortBy", "description");
                  }}
                  className="sort-table-btn"
                >
                  {messages.label.name}
                </button>
              </th>
              <th>{messages.label.detail}</th>
              {!client.nopricing && <th>{messages.label.price}</th>}
              <th>{messages.label.quantity} </th>
              <th>{messages.label.availableStock}</th>
              <th>{messages.label.unit}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {productState.products?.map((item) => (
              <tr key={item._id || item.id}>
                <td
                  className="lead-sm"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title={item.id}
                >
                  {item.id}
                </td>
                <td>
                  <img
                    // src={item.imageURL[0].replace(/ /g, "%20")}
                    src={item.imageURL[0]}
                    alt="product-image"
                    className="table-image border"
                  />
                </td>

                <td>
                  <input
                    type="button"
                    value={item.description}
                    onClick={() => onDetial(item)}
                    className="productDescription"
                    style={{
                      border: "none",
                      backgroundColor: "transparent",
                      cursor: "pointer",
                    }}
                  />
                </td>
                <td>
                  <input
                    className="btn btn-success detail-btn"
                    type="button"
                    to="/essity/addtocart"
                    value="Detail"
                    onClick={() => onDetial(item)}
                  />
                </td>
                {!client.nopricing ? <td>${item.unitPrice.toFixed(2)} </td> : ""}
                <td>
                  <ProductQuantityInput
                    item={item}
                    onChangeQuantity={(e) => {
                      onUnitChange(
                        item.id,
                        e.target.valueAsNumber,
                        item.minimumOrder,
                        item.roundingNumber
                      );
                    }}
                  />
                </td>
                <td>{item.currentStock}</td>
                
                <td>
                  <select className="sel-w form-control selectList">
                    <option value={item.unit}>{item.unit}</option>
                  </select>
                </td>
                <td>
                  <input
                    className="btn btn-info mr-1 add-btn"
                    type="button"
                    onClick={() => {
                      onAdd(item);
                    }}
                    value="Add"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
export default ProductTable;
