import React, { useEffect } from "react";
import classnames from "classnames";

const messages = {
  label: {
    personalDetails: "Personal Details",
    yourName: "Your Name",
    yourOrganization: "Your Organization",
    yourPhone: "Your Phone",
    name: "Name",
    organization: "Organization",
    phone: "Phone",
    email: "Email",
    address1: "Address 1",
    address2: "Address 2",
    town: "Town",
    deliveryDetails: "Delivery Details",
    state: "State",
    postCode: "Post Code",
    contactPhone: "Contact Phone",
    contactEmail: "Contact Email",
    reviewAnOrder: "Review An Order",
    stockReport: "Stock Report",
    manageAccount: "Manage Account",
    placeAnOrder: "Place An Order",
    privacyLinkURL:process.env.REACT_APP_MODERN_PROMOTIONS_PRIVACY
    // isTermAccepted: ``,
  },
};

const DeliveryForm = ({
  loading,
  onSubmit,
  changeInput,
  error,
  name,
  organization,
  email,
  phone,
  dname,
  dorganization,
  daddress1,
  daddress2,
  dtown,
  dstate,
  dpostcode,
  dphone,
  demail,
  isTermAccepted,
  isTermAcceptedCurrent,
}) => {
  // console.log(isTermAcceptedCurrent);
  return (
    <form
      style={{ marginBottom: "5px" }}
      onSubmit={onSubmit}
      className="border p-5"
    >
      <h4 className="text-center inline mb-4">
        {messages.label.personalDetails}
      </h4>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group row">
            <label htmlFor="name" className="col-lg-4 col-form-label">
              {messages.label.yourName}
            </label>
            <div className="col-lg-8">
              <input
                className={classnames("form-control", {
                  "is-invalid": error?.name,
                })}
                type="text"
                // placeholder="Enter the Name"
                name="name"
                value={name}
                disabled
                onChange={(e) => changeInput("name", e.target.value)}
              />
              <small>{error?.name}</small>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group row">
            <label htmlFor="name" className="col-lg-4 col-form-label">
              {messages.label.yourOrganization}
            </label>
            <div className="col-lg-8">
              <input
                className={classnames("form-control", {
                  "is-invalid": error?.organization,
                })}
                type="text"
                // placeholder="Enter the Organization"
                name="organization"
                value={organization}
                onChange={(e) => changeInput("organization", e.target.value)}
              />
              <small>{error?.organization}</small>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <div className="form-group row">
            <label htmlFor="name" className="col-lg-4 col-form-label">
              {messages.label.email}
            </label>
            <div className="col-lg-8">
              <input
                className={classnames("form-control", {
                  "is-invalid": error?.email,
                })}
                type="email"
                // placeholder="Enter the Email"
                value={email}
                onChange={(e) => changeInput("email", e.target.value)}
              />
              <small>{error?.email}</small>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group row">
            <label htmlFor="name" className="col-lg-4 col-form-label">
              {messages.label.yourPhone}
            </label>
            <div className="col-lg-8">
              <input
                className={classnames("form-control", {
                  "is-invalid": error?.phoneNumber,
                })}
                type="number"
                // placeholder="Enter the Phone"
                value={phone}
                onKeyDown={(event) => {
                  const characterCode = event.key;
                  if (characterCode === "Backspace") return;
                  const characterNumber = Number(characterCode);
                  if (characterNumber >= 0 && characterNumber <= 9) {
                    if (
                      event.currentTarget.value &&
                      event.currentTarget.value.length
                    ) {
                      return;
                    }
                  } else {
                    event.preventDefault();
                  }
                }}
                onChange={(event) => changeInput("phone", event.target.value)}
              />
              <small>{error?.phoneNumber}</small>
            </div>
          </div>
        </div>
      </div>
      <hr style={{ border: "1px solid #333" }} />
      <h4 className="text-center inline my-4">
        {messages.label.deliveryDetails}
      </h4>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group row">
            <label htmlFor="name" className="col-lg-4 col-form-label">
              {messages.label.name}
            </label>
            <div className="col-lg-8">
              <input
                className={classnames("form-control", {
                  "is-invalid": error?.dname,
                })}
                type="text"
                // placeholder="Enter The Name"
                value={dname}
                onChange={(e) => changeInput("dname", e.target.value)}
              />
              <small>{error?.dname}</small>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group row">
            <label htmlFor="name" className="col-lg-4 col-form-label">
              {messages.label.organization}
            </label>
            <div className="col-lg-8">
              <input
                className={classnames("form-control", {
                  "is-invalid": error?.dorganization,
                })}
                type="text"
                // placeholder="Enter The Organization"
                value={dorganization}
                onChange={(e) => changeInput("dorganization", e.target.value)}
              />
              <small>{error?.dorganization}</small>
            </div>
          </div>
        </div>
      </div>

      <div className="form-group row ">
        <label htmlFor="name" className="col-lg-2 col-form-label">
          {messages.label.address1}
        </label>
        <div className="col-lg-9">
          <input
            type="text"
            className={classnames("form-control", {
              "is-invalid": error?.address1,
            })}
            value={daddress1}
            onChange={(e) => changeInput("daddress1", e.target.value)}
            // placeholder="Enter The Address1"
          />
          <small>{error?.address1}</small>
        </div>
      </div>

      <div className="form-group row ">
        <label htmlFor="name" className="col-lg-2 col-form-label">
          {messages.label.address2}
        </label>
        <div className="col-lg-9">
          <input
            type="text"
            value={daddress2}
            className={classnames("form-control", {
              "is-invalid": error?.address2,
            })}
            onChange={(e) => changeInput("daddress2", e.target.value)}
          />
          <small>{error?.address2}</small>
        </div>
      </div>

      <div className="row">
        <div className="col-md-4">
          <div className="form-group row">
            <label htmlFor="address" className="col-lg-6 col-form-label">
              {messages.label.town}
            </label>
            <div className="col-lg-6">
              <input
                type="text"
                // placeholder="Enter The Town"
                className={classnames("form-control", {
                  "is-invalid": error?.town,
                })}
                value={dtown}
                onChange={(e) => changeInput("dtown", e.target.value)}
              />
              <small>{error?.town}</small>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group row">
            <label htmlFor="address" className="col-lg-3 col-form-label">
              {messages.label.state}
            </label>
            <div className="col-lg-9">
              <input
                type="text"
                // placeholder="Enter The State"
                value={dstate}
                className={classnames("form-control", {
                  "is-invalid": error?.state,
                })}
                onChange={(e) => changeInput("dstate", e.target.value)}
              />
              <small>{error?.state}</small>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group row">
            <label htmlFor="address" className="col-lg-4 col-form-label">
              {messages.label.postCode}
            </label>
            <div className="col-lg-8">
              <input
                type="number"
                // placeholder="Enter The Code"
                value={dpostcode}
                className={classnames("form-control", {
                  "is-invalid": error?.postcode,
                })}
                onKeyDown={(e) => {
                  const characterCode = e.key;
                  if (characterCode === "Backspace") return;

                  const characterNumber = Number(characterCode);
                  if (characterNumber >= 0 && characterNumber <= 9) {
                    if (e.currentTarget.value && e.currentTarget.value.length) {
                      return;
                    }
                  } else {
                    e.preventDefault();
                  }
                }}
                onChange={(event) =>
                  changeInput("dpostcode", event.target.value)
                }
              />
              <small>{error?.postcode}</small>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <div className="form-group row">
            <label htmlFor="address" className="col-lg-4 col-form-label">
              {messages.label.contactPhone}
            </label>
            <div className="col-lg-8">
              <input
                type="number"
                // placeholder="Enter The Phone"
                value={dphone}
                onKeyDown={(event) => {
                  const characterCode = event.key;
                  if (characterCode === "Backspace") return;

                  const characterNumber = Number(characterCode);
                  if (characterNumber >= 0 && characterNumber <= 9) {
                    if (
                      event.currentTarget.value &&
                      event.currentTarget.value.length
                    ) {
                      return;
                    }
                  } else {
                    event.preventDefault();
                  }
                }}
                className={classnames("form-control", {
                  "is-invalid": error?.dphoneNumber,
                })}
                onChange={(event) => changeInput("dphone", event.target.value)}
              />
              <small>{error?.dphoneNumber}</small>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group row">
            <label htmlFor="address" className="col-lg-4 col-form-label">
              {messages.label.contactEmail}
            </label>
            <div className="col-lg-8">
              <input
                type="email"
                // placeholder="Enter The Email"
                value={demail}
                className={classnames("form-control", {
                  "is-invalid": error?.demail,
                })}
                onChange={(event) => changeInput("demail", event.target.value)}
              />
              <small>{error?.demail}</small>
            </div>
          </div>
        </div>
      </div>

      {!isTermAcceptedCurrent && (
        <>
          <div className="form-group form-check">
            <input
              className="form-check-input loginCheck"
              type="checkbox"
              checked={isTermAccepted}
              onChange={(event) =>
                changeInput("isTermAccepted", event.target.checked)
              }
            />
            <label
              className="form-check-label "
              style={{
                fontSize: "20px",
              }}
            >
              Please review the
              <a
                href={`${messages.label.privacyLinkURL}`}
                target="_blank"
                rel="noreferrer"
                style={{ color: "#000" }}
              >
                <strong> privacy policy </strong>
              </a>
              and check the box to confirm your order
            </label>
          </div>
          <small style={{ color: "red" }}>{error?.isTermAccepted}</small>
        </>
      )}
      <input
        disabled={loading}
        type="submit"
        value="Submit"
        className="btn btn-success"
        style={{ float: "right" }}
      />
    </form>
  );
};

export default DeliveryForm;
