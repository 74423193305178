import React from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { Link, useHistory } from "react-router-dom";
import Navbar from "../layouts/Navbar";
import { useContext } from "react";
import EssityContext from "../../context/EssityContext";
import { useEffect } from "react";
import StripePaymentForm from "./StripePaymentForm";

const messages = {
  label: {
    payment: "Payment",
    back: "Go Back",
  },
};
const StripePayment = ({ match }) => {
  const {
    setCurrentClientUpdateData,
    client,
    StripePayment,
    error,
    removeError,
    user,
  } = useContext(EssityContext);

  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    setCurrentClientUpdateData(match.params.essity, history);
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    const cardElement = elements.getElement(
      CardExpiryElement,
      CardNumberElement,
      CardCvcElement
    );
    StripePayment(cardElement, stripe, history, match, user._id);
  };

  return (
    <div>
      <Navbar
        logout={true}
        cart={true}
        name={match.params.essity}
        logo={client?.photo}
      ></Navbar>
      <div className="payment" style={{ marginTop: "10px" }}>
        <div className="container-fluid px-5">
          <h1 className="display-4 mb-4">{messages.label.payment}</h1>
          <Link
            className="btn btn-primary mb-2"
            to={`/${match.params.essity}/cart`}
            onClick={removeError}
          >
            {messages.label.back}
          </Link>
          <div className="row">
            <div className="col-md-6 offset-md-4 px-5">
              <StripePaymentForm
                handleSubmit={handleSubmit}
                error={error}
                stripe={stripe}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StripePayment;
