import React, { useEffect, useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import EssityContext from "../../context/EssityContext";
import Navbar from "../layouts/Navbar";
import UserDetials from "./UserDetials";
import UserDeliveryDetials from "./UserDeliveryDetials";
import manageAccountIcon from "../../logo/manageAccunt.png";

const messages = {
  label: {
    manage: "Manage Account",
    goBack: "Home",
    update: "Update",
    isTermAccepted: "I have read and accept Modern Promotions ",
    privacyLink: "privacy policy",
    privacyLinkURL: process.env.REACT_APP_MODERN_PROMOTIONS_PRIVACY,
  },
};
const ManageAccount = ({ match }) => {
  let history = useHistory();
  const {
    error,
    setCurrentClientUpdateData,
    user,
    updateUser,
    clearMsg,
    client,
    removeError,
  } = useContext(EssityContext);

  const [userDefinitionState, setUserDefinitionState] = useState({
    login: "",
    name: "",
    email: "",
    password: "",
    role: "buyer",
    clientName: "",
    phone: "",
    address1: "",
    address2: "",
    postcode: "",
    organization: "",
    town: "",
    state: "",
    status: "",
    dname: "",
    demail: "",
    dphone: "",
    daddress1: "",
    daddress2: "",
    dpostcode: "",
    dstate: "",
    dtown: "",
    dorganization: "",
    isTermAccepted: false,
  });
  const clientName = client && client?.name;

  const setCurrentUserAndDeliveryDetails = () => {
    let userState = { ...user };
    let delivery = { ...user.delivery };
    setUserDefinitionState({
      ...userDefinitionState,
      login: userState.login,
      name: userState.name,
      email: userState.email,
      status: userState.userstatus,
      password: userState.password,
      clientName: userState.clientName,
      address1: userState.address1,
      address2: userState.address2,
      postcode: userState.postcode,
      phone: userState.phone,
      role: userState.role,
      organization: userState.organization,
      state: userState.state,
      town: userState.town,
      dname: delivery.name,
      dorganization: delivery.organization,
      dtown: delivery.town,
      dstate: delivery.state,
      dpostcode: delivery.postcode,
      daddress1: delivery.address1,
      daddress2: delivery.address2,
      dphone: delivery.phone,
      demail: delivery.email,
      isTermAccepted: user.isTermAccepted,
    });
  };
  useEffect(() => {
    setCurrentClientUpdateData(match.params.essity, history);
  }, []);
  useEffect(() => {
    if (JSON.stringify(user) !== JSON.stringify({})) {
      setCurrentUserAndDeliveryDetails();
    }
  }, [clientName, user]);

  const onSubmit = (e) => {
    e.preventDefault();
    let newUser = {
      clientName: userDefinitionState.clientName,
      login: userDefinitionState.login,
      name: userDefinitionState.name,
      email: userDefinitionState.email,
      password: userDefinitionState.password,
      role: userDefinitionState.role,
      address1: userDefinitionState.address1,
      address2: userDefinitionState.address2,
      postcode: userDefinitionState.postcode,
      phone: userDefinitionState.phone,
      organization: userDefinitionState.organization,
      state: userDefinitionState.state,
      town: userDefinitionState.town,
      userstatus: userDefinitionState.status,
      dname: userDefinitionState.dname,
      dorganization: userDefinitionState.dorganization,
      dtown: userDefinitionState.dtown,
      dstate: userDefinitionState.dstate,
      dpostcode: userDefinitionState.dpostcode,
      daddress1: userDefinitionState.daddress1,
      daddress2: userDefinitionState.daddress2,
      dphone: userDefinitionState.dphone,
      demail: userDefinitionState.demail,
      isTermAccepted: userDefinitionState.isTermAccepted,
    };
    updateUser(newUser, user._id, clientName, history, match);
  };

  const changeInput = (inputName, inputValue) => {
    setUserDefinitionState({
      ...userDefinitionState,
      [inputName]: inputValue,
    });
  };

  return (
    <div>
      <Navbar
        logout={true}
        name={match.params.essity}
        logo={client !== undefined && client.photo ? client.photo : ""}
      ></Navbar>

      <div className=" container-fluid px-5 mb-4">
        <h1 className="display-4">
          {messages.label.manage}
          <img
            src={manageAccountIcon}
            alt="manageAccountIcon"
            className="iconOnCustomerSide"
          />
        </h1>
        <Link
          className="btn text-center mb-3 mr-3 button "
          to={`/${match.params.essity}/dashboard`}
          onClick={() => {
            clearMsg();
            removeError();
          }}
        >
          {messages.label.goBack}
        </Link>

        <div
        // style={{ marginBottom: "1000px !important", backgroundColor: "red" }}
        >
          <form onSubmit={onSubmit}>
            <div>
              <UserDetials
                error={error}
                changeInput={changeInput}
                login={userDefinitionState.login}
                password={userDefinitionState.password}
                name={userDefinitionState.name}
                organization={userDefinitionState.organization}
                phone={userDefinitionState.phone}
                email={userDefinitionState.email}
                address1={userDefinitionState.address1}
                address2={userDefinitionState.address2}
                town={userDefinitionState.town}
                state={userDefinitionState.state}
                postcode={userDefinitionState.postcode}
                userStatus={userDefinitionState.status}
              />

              <div className="form-group form-check">
                <input
                  className="form-check-input loginCheck mr-2"
                  type="checkbox"
                  checked={userDefinitionState.isTermAccepted}
                  onChange={(e) =>
                    changeInput("isTermAccepted", e.target.checked)
                  }
                />
                <label
                  className="form-check-label px-2"
                  style={{ color: "#000" }}
                >
                  {messages.label.isTermAccepted}
                  <a
                    href={`${messages.label.privacyLinkURL}`}
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "#000" }}
                  >
                    <strong> {messages.label.privacyLink}</strong>
                  </a>
                </label>
              </div>

              <UserDeliveryDetials
                error={error}
                dname={userDefinitionState?.dname}
                dorganization={userDefinitionState?.dorganization}
                daddress1={userDefinitionState?.daddress1}
                daddress2={userDefinitionState?.daddress2}
                dtown={userDefinitionState?.dtown}
                dstate={userDefinitionState?.dstate}
                dpostcode={userDefinitionState?.dpostcode}
                dphone={userDefinitionState?.dphone}
                demail={userDefinitionState?.demail}
                changeInput={changeInput}
              />
            </div>

            <div className=" d-flex justify-content-end ">
              <button type="submit" className="btn button">
                {messages.label.update}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ManageAccount;
