import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isClient, isCategoryEnabled } from "../utils/clientStatus";
const CategoryRouteProtect = ({ component: Component, ...rest }) => {
  const clientName = localStorage.getItem("clientname");

  return (
    <Route
      {...rest}
      render={(props) =>
        isClient() && isCategoryEnabled() ? (
          <Redirect to={`/${clientName}/dashboard`}></Redirect>
        ) : (
          <Component {...props}></Component>
        )
      }
    ></Route>
  );
};

export default CategoryRouteProtect;
