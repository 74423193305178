import {
  GET_CLIENT,
  CLEAR_CLIENT,
  LOGIN_USER,
  ALL_STOCK,
  ALL_CATEGORIES,
  ALL_PRODUCTS,
  CART_ITEMS,
  CART_ITEM,
  TOTAL,
  DELETE_ITEM,
  ADD_ORDERS,
  GET_ORDERS,
  UPDATE_ORDERS,
  ERROR,
  SET_LOADING,
  MSG,
  DEL_CARD,
  SET_LOADINGFALSE,
  PRODUCTMANAGER,
} from "./types";

export default (state, action) => {
  switch (action.type) {
    case ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case MSG: {
      return {
        ...state,
        msg: action.payload,
      };
    }
    case PRODUCTMANAGER:
      return {
        ...state,
        productManager: action.payload,
        loading: false,
      };
    case SET_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case SET_LOADINGFALSE:
      return {
        ...state,
        loading: false,
      };
    case GET_CLIENT:
      return {
        ...state,
        client: action.payload,
      };
    case ALL_STOCK:
      return {
        ...state,
        stock: action.payload,
        loading: false,
      };
    case LOGIN_USER:
      return {
        ...state,
        user: action.payload,
        loading: false,
      };
    case ALL_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
        loading: false,
      };
    case ALL_PRODUCTS:
      return {
        ...state,
        products: action.payload,
        loading: false,
      };
    case CART_ITEMS:
      return {
        ...state,
        cardItem: [...state.cardItem, action.payload],
      };
    case CART_ITEM:
      return {
        ...state,
        cardItem: action.payload,
      };
    case TOTAL:
      return {
        ...state,
        grandtotal: action.payload,
      };
    case DELETE_ITEM:
      return {
        ...state,
        cardItem: state.cardItem.filter((item) => item.id !== action.payload),
      };
    case GET_ORDERS:
      return {
        ...state,
        orders: action.payload,
        loading: false,
      };
    case DEL_CARD:
      return {
        ...state,
        cardItem: [],
      };
    case "CATEGORYPAGINATION":
      return {
        ...state,
        categoryPagination: action.payload,
      }
    case "CATEGORYSKUPAGINATION":
      return {
        ...state,
        categorySkuPagination: action.payload,
      }

    case "ORDERPAGINATION":
      return {
        ...state,
        orderPagination: action.payload,
      }
    case "STOCKREPORTPAGINATION":
      return {
        ...state,
        stockReportPagination: action.payload,
      }
    case "PRODUCTMANAGERWITHWAREHOUSE":
      return {
        ...state,
        productManagerWithWarehouse: action.payload,
      }

    default: {
      return state;
    }
  }
};
