import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory ,useLocation} from "react-router-dom";
import Navbar from "../layouts/Navbar";
import EssityContext from "../../context/EssityContext";
import CartTable from "./CartTable";
import CartLoginForm from "./CartLoginForm";
import ClearCartModel from "./ClearCartModel";

const messages = {
  label: {
    summary: "Order Summary",
    goBack: "Go Back",
    noCard: "Cart is empty",
    next: "Next",
    clearCard: "Clear Cart",
  },
};

const Cart = ({ match }) => {
  const history = useHistory();
  const location = useLocation();
  const {
    cardItem,
    setCardItem,
    grandTotal,
    grandtotal,
    removeItem,
    user,
    setCurrentClientUpdateData,
    client,
    loginUser,
    error,
    removeError,
    clearCardItem,
  } = useContext(EssityContext);

  const [delivery, setDelivery] = useState("");
  const [cartState, setCartState] = useState({
    next: false,
    email: "",
    password: "",
    delivery: "",
    clearCardArray: false,
  });
  const clientName = client && client?.name;

  const changeCartState = (field, value) => {
    setCartState({
      ...cartState,
      [field]: value,
    });
  };

  const onClick = (id) => {
    removeItem(id);
    if (cardItem.length === 1) {
      localStorage.removeItem("card");
      localStorage.removeItem("total");
    }
  };

  const clearCard = () => {
    localStorage.removeItem("card");
    localStorage.removeItem("total");
    clearCardItem();
  };

  useEffect(() => {
    const localClient = localStorage.getItem("clientname");
    // if (match.params.essity === localClient) {
    setCurrentClientUpdateData(match.params.essity, history);
    let totalPrice = 0;
    if (cardItem.length !== 0) {
      localStorage.setItem("card", JSON.stringify(cardItem));
      cardItem.forEach((item) => {
        totalPrice += parseFloat(item.totalPrice);
      });
      localStorage.setItem("total", totalPrice);
      grandTotal();
    } else {
      setCardItem(user);
      grandTotal();
    }

    if (client?.isDeliveryEnabled) {
      setDelivery(client.delivery);
    }
  }, [clientName, cardItem]);

  const onDelivery = (e) => {
    if (client?.payment) {
      history.push({
        pathname: `/${match.params.essity}/payment`,
      });
    } else {
      history.push({
        pathname: `/${match.params.essity}/delivery`,
      });
    }
  };

  const GuestLogin = () => {
    changeCartState("next", true);
    removeError();
  };

  const onSubmit = (e) => {
    e.preventDefault();
    loginUser(
      cartState.email,
      cartState.password,
      false,
      false,
      clientName,
      client,
      match,
      "delivery"
    );
  };

  const goBackToCategories = ()=>{
    const {state} = location;
    history.push({
      pathname: client?.isCategoryEnabled ? `/${match.params.essity}/categories` : `/${match.params.essity}/categories/${client?._id}`,
    })
  }
  return (
    <div>
      <Navbar
        logout={true}
        name={match.params.essity}
        logo={client !== undefined && client.photo ? client.photo : ""}
      ></Navbar>
      <div className="ORDERSUMMARY">
        <div className="container-fluid px-5">
          <h1 className="display-3">{messages.label.summary}</h1>
          {/* <Link
            className="btn btn-primary mb-3"
            to={
              client?.isCategoryEnabled
                ? `/${match.params.essity}/categories`
                : `/${match.params.essity}/categories/${client?._id}`
            }
          >
            {messages.label.goBack}
          </Link> */}
          <button className="btn btn-primary mb-3" onClick={goBackToCategories} >
          {messages.label.goBack}
          </button>
          {cardItem.length !== 0 && (
            <button
              className="btn btn-primary mb-3 ml-3"
              data-toggle="modal"
              data-target="#clearCardModel"
              onClick={() => {
                setCartState({
                  ...cartState,
                  clearCardArray: true,
                });
              }}
            >
              {messages.label.clearCard}
            </button>
          )}
          {cardItem.length !== 0 ? (
            <div>
              <CartTable
                onClick={onClick}
                client={client}
                cardItem={cardItem}
                delivery={client?.isDeliveryEnabled ? client.delivery : ""}
                grandtotal={grandtotal}
              />
              <div className="mt-2">
                {JSON.stringify(user) === JSON.stringify({}) ? (
                  <input
                    className="btn btn-secondary mb-4"
                    data-toggle="modal"
                    data-target="#modalLoginForm"
                    value="Login "
                    onClick={GuestLogin}
                    type="button"
                  />
                ) : (
                  <Link className="btn btn-secondary" onClick={onDelivery}>
                    {messages.label.next}
                  </Link>
                )}
              </div>
            </div>
          ) : (
            <h1 className="text-center mt-5 border shadow py-5">
              {messages.label.noCard}{" "}
            </h1>
          )}
        </div>
        {cartState.next && (
          <CartLoginForm
            setEmail={(e) => changeCartState("email", e)}
            setPassword={(e) => changeCartState("password", e)}
            onSubmit={onSubmit}
            error={error}
            user={user}
          />
        )}
        {cartState.clearCardArray && <ClearCartModel clearCard={clearCard} />}
      </div>
    </div>
  );
};

export default Cart;
