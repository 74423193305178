import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";
import { Link, useLocation, useHistory } from "react-router-dom";
import EssityContext from "../../context/EssityContext";
import Navbar from "../layouts/Navbar";
import ALerts from "../utils/Alerts";

const messages = {
  label: {
    goBack: "Go Back",
    addedCart: "added to your cart",
    quantityToOrder: "Quantity To Order",
    pleaseOrder: "Please order minimum of",
    product: "Product Manager",
    description: "Description here",
  },
};

const ProductDetial = ({ match }) => {
  const location = useLocation();
  const history = useHistory();
  const { minimumOrder, productManager, imageURL, itemNote, roundingNumber } =
    location.state.item;

  const {
    cardItems,
    user,
    msg,
    clearMsg,
    setCurrentClientUpdateData,
    client,
    setMsg,
  } = useContext(EssityContext);
  const [addedItem, setAddedItem] = useState(false);
  const [productDetailState, setProductDetailState] = useState({
    quantity: location.state.item.minimumOrder || 1,
    description: "",
    productImages: [],
    currentProductImage: "",
  });
  useEffect(() => {
    setProductDetailState({
      ...productDetailState,
      productImages: imageURL,
      currentProductImage: imageURL[0]
    })
  }, [imageURL])

  const changeProductDetails = (field, value) => {
    setProductDetailState({
      ...productDetailState,
      [field]: value,
    });
  };
  const onAdd = (item) => {
    let { quantity, description } = productDetailState;
    if (isNaN(quantity) || quantity === 0) {
      setMsg("Please add some quantity");
    } else if (productDetailState.quantity < item.minimumOrder) {
      setMsg(`Please order minimum of ${item.minimumOrder}`);
    } else {
      if (item.roundingNumber !== 0) {
        let newUnit = quantity - item.minimumOrder;
        newUnit = newUnit % item.roundingNumber;
        if (newUnit === 0) {
          cardItems(item, user, quantity, description);
          setAddedItem(true);
        } else {
          setMsg(`Please order in multiples of ${item.roundingNumber}`);
        }
      } else {
        
        cardItems(item, user, quantity, description);
        setAddedItem(true);
      }
    }
    setTimeout(() => {
      clearMsg();
    }, 3000);
  };

  useEffect(() => {
    const localClient = localStorage.getItem("clientname");
    if (match.params.essity === localClient) {
      setCurrentClientUpdateData(match.params.essity, history);
    } else {
      history.push({
        pathname: `/${match.params.essity}`,
      });
    }
    return;
  }, []);

  const goToCategories = () => {
    if (client?.isCategoryEnabled) {
      history.push({
        pathname: `/${match.params.essity}/categories`,
      });
    } else {
      history.push({
        pathname: `/${match.params.essity}/categories/${client._id}`,
        state: { categorySkusAndDiscriptionFilter: location.state.categorySkusAndDiscriptionFilter,
        }
      });
    }
  };
  const goBack = () => {
    history.push({
      pathname: `/${match.params.essity}/categories/${location.state.id}`,
      state: { categorySkusAndDiscriptionFilter: location.state.categorySkusAndDiscriptionFilter
      }
    })
  }
  const setProductImageOnClick = (selectedProductImage) => {
    setProductDetailState({
      ...productDetailState,
      currentProductImage: selectedProductImage
    })
  }

  const goToPlaceOrderCart = ()=>{
    history.push({
      pathname: `/${match.params.essity}/cart`,
      state: { categorySkusAndDiscriptionFilter: location.state.categorySkusAndDiscriptionFilter
      },
    });
  }

  return (
    <>
      <Navbar
        cart={true}
        logout={true}
        name={match.params.essity}
        logo={client?.photo || ""}
      ></Navbar>
      {msg && <ALerts msg={msg} />}
      <div className="add-to-cart" style={{ marginTop: "10px" }}>
        <div className="container-fluid px-5 mb-4">
          <h1 className="display-4  mb-5">{location.state.item.description}</h1>
          <input
            className="btn btn-dark mb-2 mr-2"
            onClick={goBack}
            value={messages.label.goBack}
            style={{ width: "100px" }}
          />


          {client?.isCategoryEnabled && (
            <Link
              className="btn btn-primary mb-2"
              to={`/${match.params.essity}/categories`}
            >
              Back To Categories
            </Link>
          )}

          <div className="row">
            <div className="col-md-4">
              <img
                
                src={productDetailState.currentProductImage}
                alt="product-image"
                className="d-block img-fluid border mainProductImage"
              />
            </div>
            <div className="col-md-2">
              <div className="sliderForProductImage">
                { productDetailState.productImages?.map((singleImage) => (
                  <div className="row">
                    <div className="col">
                      <img
                        src={singleImage}
                        alt="product-image"
                        className="d-block img-fluid slidingImage"
                        onClick={() => setProductImageOnClick(singleImage)}
                      />
                    </div>
                  </div>

                ))}
              </div>
              
            </div>
            <div className="col-md-6 p-4">
              {/* <h4 className="text-left mb-2" style={{ fontWeight: "bold" }}>
                    {messages.label.quantityToOrder}
                  </h4> */}
              <div className="row mb-4">
                <div className="col-md-4">
                  <div>{messages.label.pleaseOrder}</div>
                </div>
                <div className="col-md-8">
                  <input
                    type="number"
                    defaultValue={minimumOrder ? minimumOrder : 1}
                    // value={minimumOrder}
                    className="form-control product-quan addQuantity"
                    min={minimumOrder ? minimumOrder : 1}
                    // step={minimumOrder}
                    step={roundingNumber == 0 ? 1 : roundingNumber}
                    onPaste={(e) => {
                      e.preventDefault();
                    }}
                    onKeyDown={(e) => {
                      if (e.keyCode == 109 || e.keyCode == 189) {
                        e.preventDefault();
                      }
                    }}
                    onChange={(e) =>
                      changeProductDetails("quantity", e.target.valueAsNumber)
                    }
                  />
                </div>
              </div>

              {productManager && (
                <div className="row mb-5">
                  <div className="col-md-4">{messages.label.product}</div>
                  <div className="col-md-8">
                    <input
                      type="text"
                      className="form-control"
                      value={productManager ? productManager : "Not Available"}
                      disabled
                    />
                  </div>
                </div>
              )}

              <div className="row mb-2">
                <div className="col-md-4">{messages.label.description}</div>
                <div className="col-md-8">
                  <textarea
                    placeholder={itemNote}
                    className="form-control border border-dark"
                    rows="5"
                    cols="50"
                    onChange={(e) =>
                      changeProductDetails("description", e.target.value)
                    }
                    disabled
                  ></textarea>
                </div>
              </div>
              <div>
                <input
                  className="btn btn-secondary "
                  type="button"
                  onClick={() => onAdd(location.state.item)}
                  value="Add To Cart"
                  style={{ float: "right" }}
                />
              </div>
              {addedItem && (
                <div className="mt-5 pt-2 border shadow-lg p-2">
                  <h5>
                    <i className="fa fa-check mr-1" aria-hidden="true"></i>
                    {location.state.item.description} added to your cart
                  </h5>
                  <div className="d-flex justify-content-between">
                    <input
                      className="btn btn-info "
                      type="button"
                      onClick={() =>
                        
                        goToPlaceOrderCart()
                      }
                      value="Review Cart"
                    />

                    {client?.isCategoryEnabled && (
                      <input
                        className="btn btn-primary "
                        type="button"
                        onClick={goToCategories}
                        value="Return To Categories"
                      // style={{ float: "right" }}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductDetial;
